import { DateTime } from 'luxon';
import { convertToRoman } from '../../../../common/utils/number.utils';
import { Fragment } from 'react';
import { JournalScheduleEntity } from '../../../entity/items.entity';

export type JournalHeaderItemProps = {
  item?: JournalScheduleEntity;
  type: 'ITEM' | 'TOTAL' | 'FIXED';
  isSemester?: boolean;
};

export const JournalHeaderItem = ({
  item,
  type,
  isSemester,
}: JournalHeaderItemProps) => {
  return (
    <Fragment>
      <button
        className={`flex flex-col justify-center items-center text-center border-r border-b dark:border-[#333333] text-sm w-10 ${
          isSemester ? 'h-38' : 'h-32'
        } ${type !== 'ITEM' && 'bg-neutral-100 dark:bg-neutral-900'}`}
        disabled={type !== 'ITEM'}
        onClick={() => {
          if (type !== 'ITEM') {
            return;
          }
        }}
      >
        <span
          className={`rotate-180 whitespace-nowrap font-semibold`}
          style={{ writingMode: 'vertical-rl' }}
        >
          {type === 'ITEM'
            ? `${DateTime.fromSeconds(item?.date ?? 0).toLocaleString({
                month: 'long',
                day: 'numeric',
              })}, ${convertToRoman(item?.sort ?? 1)}`
            : type === 'TOTAL'
            ? 'Итоговая'
            : 'Исправление'}
        </span>
      </button>

      {/*{item && groupId && subjectId && (*/}
      {/*  <JournalScheduleModifyModalComponent*/}
      {/*    showed={showed}*/}
      {/*    groupId={groupId}*/}
      {/*    subjectId={subjectId}*/}
      {/*    item={item}*/}
      {/*    onClose={() => setShowed(false)}*/}
      {/*  />*/}
      {/*)}*/}
    </Fragment>
  );
};
