import { ScheduleEntity } from '../../../schedule/schedule.entity';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { Profile } from '../../../common/component/profile/profile.component';
import { Button } from '../../../common/component/button/button.component';
import { Badge2 } from '../../../common/component/badge2/badge';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../common/app/auth/auth.context';
import { PermissionType } from '../../../common/app/auth/auth-role-permission.type';

export type ScheduleItemProps = {
  schedule: ScheduleEntity;

  journalVisible: boolean;
};

export const ScheduleItem = ({
  schedule,
  journalVisible,
}: ScheduleItemProps) => {
  const navigate = useNavigate();
  const { result } = useAuth();

  const [progress, setProgress] = useState(0);
  const [left, setLeft] = useState(0);

  const now = useCallback(
    () =>
      Math.abs(
        DateTime.now().startOf('day').diff(DateTime.now(), 'minutes').minutes,
      ),
    [],
  );

  useEffect(() => {
    if (!schedule.isPlaying) {
      return;
    }

    const update = () =>
      setProgress(
        (100 * (now() - schedule.start)) / (schedule.end - schedule.start),
      );

    if (progress === 0) {
      return update();
    }

    const timeout = setTimeout(() => update(), 5000);

    return () => clearTimeout(timeout);
  }, [schedule, progress, now]);

  useEffect(() => {
    if (!schedule.isSoon) {
      return;
    }

    const update = () => setLeft(() => Math.ceil(schedule.start - now()));

    if (left === 0) {
      return update();
    }

    const timeout = setTimeout(() => update(), 5000);

    return () => clearTimeout(timeout);
  }, [schedule, left, now]);

  return (
    <div className={'flex flex-col gap-2'}>
      <div className={'flex flex-row justify-between items-center font-medium'}>
        <span>
          {result?.user?.type === 'STUDENT' ? (
            <Profile user={schedule.teacher} size={8} />
          ) : (
            schedule.group.title
          )}
        </span>
        {schedule.isPlaying && <span>Идет занятие</span>}
        {schedule.isSoon && (
          <span className={'whitespace-nowrap'}>До начала {left} м.</span>
        )}

        {!schedule.isPlaying && !schedule.isSoon && (
          <span>
            {`${DateTime.now()
              .startOf('day')
              .plus({ minute: schedule.start })
              .toFormat('HH:mm')}
            —
            ${DateTime.now()
              .startOf('day')
              .plus({ minute: schedule.end })
              .toFormat('HH:mm')} (${schedule.sort + 1} пара)`}
          </span>
        )}
      </div>

      <div className={'flex flex-row justify-between'}>
        <span>{schedule.plan.subject.short}</span>

        <span>
          {schedule?.isOnline ? (
            <Badge2 color={'yellow'}>Дистанционно</Badge2>
          ) : (
            schedule.classroom.title
          )}
        </span>
      </div>

      {schedule.isPlaying && (
        <div className={'flex flex-col gap-2 mt-2'}>
          <div
            className={
              'relative w-[100%] h-4 bg-[#EDEEF0] dark:bg-black/40 rounded-lg'
            }
          >
            <div
              className={
                'relative top-1 left-1 max-w-[98%] h-2 bg-green-500 rounded-full'
              }
              style={{ width: `${progress}%` }}
            />
          </div>

          <div className={'flex flex-row justify-between'}>
            <span>
              {DateTime.now()
                .startOf('day')
                .plus({ minute: schedule.start })
                .toFormat('HH:mm')}
            </span>
            <span>
              {DateTime.now()
                .startOf('day')
                .plus({ minute: schedule.end })
                .toFormat('HH:mm')}
            </span>
          </div>
        </div>
      )}

      {journalVisible &&
        result?.user?.type === 'TEACHER' &&
        result?.permissions?.includes(
          PermissionType.JOURNAL_MODULE_PREVIEW,
        ) && (
          <Button
            className={'mt-4'}
            onClick={() =>
              navigate(
                `/journals/items/subject/?groupId=${schedule.groupId}&subjectId=${schedule.plan.subject.id}`,
                {},
              )
            }
          >
            Открыть журнал
          </Button>
        )}
    </div>
  );
};
