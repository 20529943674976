import { Profile } from '../../../../common/component/profile/profile.component';
import { UserEntity } from '../../../../user/user.entity';
import { Badge2 } from '../../../../common/component/badge2/badge';
import { JournalStudentEntity } from '../../../entity/items.entity';

export type JournalTableStudentProfileProps = {
  index: number;
  student: JournalStudentEntity;
};

export const JournalTableStudentProfile = ({
  index,
  student,
}: JournalTableStudentProfileProps) => {
  return (
    <div
      className={
        'sticky left-0 z-20 bg-white dark:bg-[#222222] text-sm flex h-10 w-96 shrink-0 items-center border-r border-b dark:border-[#333333] pl-4'
      }
    >
      <Profile
        user={student as unknown as UserEntity}
        simple={true}
        size={8}
        prefix={<span className={'w-4'}>{index + 1}.</span>}
        suffix={
          student.isCaptain ? (
            <Badge2 color={'green'}>Староста</Badge2>
          ) : (
            <div />
          )
        }
      />
    </div>
  );
};
