import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useList } from 'react-use';
import { UserEntity } from './user.entity';
import { RoleEntity } from '../role/role.entity';
import { addUserRole, findAllUserRoles, removeUserRole } from './user.service';
import { useNotifier } from '../common/app/notification/notification-context';
import { findOne } from '../common/entity/entity.service';

export type UserEntityType = UserEntity | undefined;

export type UserContextType = {
  user: UserEntityType;
  setUser: (value: UserEntityType) => void;

  roles: RoleEntity[];
  addRole: (id: number) => void;
  removeRole: (id: number) => void;
};

export const UserContext = createContext<UserContextType>(
  {} as UserContextType,
);

export const UserProvider = ({ children }: PropsWithChildren) => {
  const { notify } = useNotifier();

  const [user, setUser] = useState<UserEntityType>(undefined);
  const [roles, { push, filter, clear }] = useList<RoleEntity>([]);

  useEffect(() => {
    if (!user) {
      return;
    }

    findAllUserRoles(user.id).then(response => {
      clear();
      push(...response.data);
    });
  }, [user, push, clear]);

  const addRole = useCallback(
    (id: number) => {
      if (!user) {
        return;
      }

      addUserRole(user.id, id)
        .then(() => findOne<RoleEntity>('roles', id))
        .then(response => {
          push(response.data);
          notify('success', 'Роль успешно добавлена');
        })
        .catch(() => notify('error', 'Ошибка при добавлении классификации'));
    },
    [roles, push],
  );

  const removeRole = useCallback(
    (id: number) => {
      if (!user) {
        return;
      }

      removeUserRole(user.id, id)
        .then(() => {
          filter(item => item.id !== id);
          notify('success', 'Классификация успешно удалена');
        })
        .catch(() => notify('error', 'Ошибка при удалении классификации'));
    },
    [roles, filter],
  );

  const value = useMemo(
    () => ({
      user,
      setUser,

      roles,
      addRole,
      removeRole,
    }),
    [user, setUser, roles, addRole, removeRole],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUsers = () => useContext<UserContextType>(UserContext);
