import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import {
  AuthAccountResponse,
  AuthLoginResponse,
  AuthRegisterInstitution,
  AuthVerifyResponse,
} from './auth.entity';
import { DateTime } from 'luxon';
import { ErrorEntity } from '../networking/error.entity';
import { useNotifier } from '../notification/notification-context';
import { useLocalStorage } from 'react-use';
import { api } from '../networking/axios';
import { useQuery } from '@tanstack/react-query';
import logo from '../../assets/logo.svg';
import { UserEntity } from '../../../user/user.entity';
import { getOne } from '../../entity/entity.service';

export type AuthUserType = AuthAccountResponse | undefined;

export type AuthModalNoneType = {
  type: 'none';
};

export type AuthModalConnectType = {
  type: 'connect';
};

export type AuthModalDevelopersType = {
  type: 'developers';
};

export type SimulationModalDevelopersType = {
  type: 'simulation';
};

export type AuthModalType =
  | AuthModalNoneType
  | AuthModalConnectType
  | AuthModalDevelopersType
  | SimulationModalDevelopersType;

export type AuthState = {
  mail: string | undefined;
  magic: string | undefined;
  lastSent: number;
};

export type AuthContextType = {
  result: AuthUserType;
  auth: AuthState;
  token: string | undefined;
  developers: UserEntity[];

  login: (mail: string) => void;
  verify: (code: number) => void;
  logout: () => void;
  simulateUser: (id: UserEntity['id'] | 'none') => Promise<void>;
  registerInstitution: (values: AuthRegisterInstitution) => void;

  error: string | undefined;
  clearError: () => void;

  modal: AuthModalType;
  setModal: (value: AuthModalType) => void;

  loaded: boolean;
  isLoading: boolean;
};

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType,
);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const { notify } = useNotifier();

  const [tokens, setTokens, clearTokens] = useLocalStorage<AuthVerifyResponse>(
    'tokens',
    undefined,
  );
  const [result, setResult] = useState<AuthUserType>(undefined);
  const [auth, setAuth] = useState<AuthState>({} as AuthState);

  const [modal, setModal] = useState<AuthModalType>({
    type: 'none',
  });
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const { data } = useQuery({
    queryKey: ['developers'],
    queryFn: () => getOne<UserEntity[]>('/users/developers/'),
  });

  useEffect(() => {
    api.interceptors.request.clear();
    api.interceptors.response.clear();

    api.interceptors.request.use(async config => {
      const base = config.baseURL ?? '';
      const url = config.url ?? '';
      const method = (config.method ?? 'get').toLowerCase();

      // Если метод авторизации или подтверждения, то ничего делать не нужно
      if (
        url.includes('/auth/code') ||
        url.includes('/auth/login') ||
        base.includes('files')
      ) {
        return config;
      }

      // Если метод создания новой пары токенов, то нужно указать токен возобновления
      if (url.includes('/auth/token') && method === 'post') {
        config.headers['Authorization'] = `Bearer ${tokens?.refresh?.token}`;

        return config;
      }

      // Если используется другой метод, то нужно указать токен доступа
      config.headers['Authorization'] = `Bearer ${tokens?.access?.token}`;

      return config;
    });

    api.interceptors.response.use(
      response => response,
      async error => {
        const request = error.config;
        const response = error.response;

        if (response?.status === 401 && request._retry) {
          clearTokens();
          setAuth({} as AuthState);
          setResult(undefined);
          setLoading(false);
          setLoaded(true);

          return Promise.reject(error);
        }

        if (
          response?.status === 401 &&
          !request._retry &&
          request.url.includes('/auth/token') &&
          request.method.toLowerCase() !== 'post'
        ) {
          request._retry = true;

          const response = await api
            .post<AuthVerifyResponse>('/auth/token')
            .then(response => setTokens(response.data))
            .catch(() => undefined);

          if (!response) {
            clearTokens();
            setAuth({} as AuthState);
            setResult(undefined);
            setLoading(false);
            setLoaded(true);

            return Promise.reject(error);
          }

          return api(request);
        }

        return Promise.reject(error);
      },
    );

    if (!tokens || !tokens.access || !tokens.refresh) {
      clearTokens();
      setAuth({} as AuthState);
      setResult(undefined);
      setLoading(false);
      setLoaded(true);

      return;
    }

    api
      .get<AuthAccountResponse>('/auth/token')
      .then(response => setResult(response.data))
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  }, [tokens, clearTokens]);

  const registerInstitution = useCallback(
    (data: AuthRegisterInstitution) =>
      api
        .post('/auth/register/', data)
        .then(() => {
          setModal({ type: 'none' });
          notify('success', 'Заявка успешно создана');
        })
        .catch(() => notify('error', 'Ошибка при создании заявки')),
    [],
  );

  const login = useCallback(
    (mail: string) => {
      setError(undefined);
      setLoading(true);

      api
        .post<AuthLoginResponse>('/auth/code/', {
          mail,
        })
        .then(response =>
          setAuth(current => ({
            ...current,

            mail,
            magic: response.data.magic,
            lastSent: DateTime.now().toUnixInteger(),
          })),
        )
        .catch((error: AxiosError<ErrorEntity>) =>
          setError(error.response?.data?.message),
        )
        .finally(() => setLoading(false));
    },
    [setLoading, setAuth],
  );

  const verify = useCallback(
    (code: number) => {
      setLoading(true);

      api
        .post<AuthVerifyResponse>('/auth/login', {
          mail: auth.mail,
          magic: auth.magic,
          code,
        })
        .then(response => setTokens(response.data))
        .catch((error: AxiosError<ErrorEntity>) =>
          setError(error.response?.data?.message),
        )
        .finally(() => setLoading(false));
    },
    [auth.magic, auth.mail, setTokens],
  );

  const logout = useCallback(() => {
    clearTokens();
    localStorage.removeItem('tokens');
  }, [clearTokens]);

  const clearError = useCallback(() => {
    setError(undefined);
  }, [setError]);

  const simulateUser = useCallback<AuthContextType['simulateUser']>(id => {
    if (id !== 'none') {
      return api
        .post<AuthAccountResponse>(`/auth/simulate/${id}`)
        .then(response => setResult(response.data));
    }
    return api
      .delete<AuthAccountResponse>('/auth/simulate')
      .then(response => setResult(response.data));
  }, []);

  const value = useMemo<AuthContextType>(
    () => ({
      result,
      auth,
      token: tokens?.access?.token,

      login,
      verify,
      logout,
      simulateUser,

      developers: data?.data ?? [],
      registerInstitution,

      modal,
      setModal,

      loaded,
      isLoading,
      error,
      clearError,
    }),
    [
      result,
      auth,
      login,
      verify,
      logout,
      simulateUser,
      registerInstitution,
      data,
      modal,
      setModal,
      loaded,
      isLoading,
      error,
      clearError,
    ],
  );

  return (
    <AuthContext.Provider value={value}>
      {loaded ? (
        children
      ) : (
        <div className={'h-[100vh] flex flex-col items-center justify-center'}>
          <div className={'flex items-center gap-2 whitespace-nowrap'}>
            <img src={logo} className={'h-16 w-16 rounded-lg'} />
            <span className={'font-semibold text-3xl'}>Платформа</span>
          </div>
        </div>
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext<AuthContextType>(AuthContext);
