import { Card } from '../../../common/block/card.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useBuildingTime } from '../../context/time.context';
import { DateTime } from 'luxon';
import Datepicker from 'react-tailwindcss-datepicker';
import { firstLetterUppercase } from '../../../common/utils/string.utils';

export const TimeScheduleShorted = () => {
  const { dates, set, unset } = useBuildingTime();

  return (
    <Card className={'p-4 flex flex-col gap-4 justify-between items-center'}>
      <div className={'flex flex-row justify-between w-full items-center'}>
        <div className={'text-lg font-bold'}>Даты сокращенного расписания</div>
        <div className={'flex flex-row gap-2 items-center'}>
          <Datepicker
            inputClassName={
              'px-4 py-2 w-full rounded-lg text-md bg-[#F0F2F5] border border-[#D3D5D7] focus:border-[#3375f6] dark:bg-[#333333] dark:border-none dark:text-white dark:focus:border-[#3375f6] dark:placeholder-[#e1e3e6]'
            }
            placeholder={'Календарь'}
            asSingle={true}
            useRange={false}
            i18n={'ru'}
            startWeekOn={'mon'}
            onChange={value => {
              if (!value) {
                return;
              }

              set(
                DateTime.fromSQL(value.startDate as string)
                  .startOf('day')
                  .toUnixInteger(),
              );
            }}
            value={null}
          />
        </div>
      </div>

      {dates.length > 0 && (
        <div className={'flex flex-col gap-2 w-full'}>
          {dates.map((date, index) => (
            <div key={index} className={'flex flex-row justify-between'}>
              <div className={'flex flex-row gap-2'}>
                <span className={'w-8'}>{index + 1}.</span>
                <span>
                  {firstLetterUppercase(
                    DateTime.fromSeconds(date).toFormat('DDDD'),
                  )}
                </span>
              </div>

              <button onClick={() => unset(date)}>
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
          ))}
        </div>
      )}
    </Card>
  );
};
