import { useProfile } from '../../profile.context';
import { Card } from '../../../common/block/card.component';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../common/component/button/button.component';
import { useAuth } from '../../../common/app/auth/auth.context';
import { PermissionType } from '../../../common/app/auth/auth-role-permission.type';

export const AvailabilitiesBoss = () => {
  const navigate = useNavigate();
  const { result } = useAuth();
  const { availabilities } = useProfile();

  if (availabilities.boss.length === 0) {
    return <div />;
  }

  return (
    <div className={'flex flex-col gap-4'}>
      <div className={'flex flex-row items-end justify-end'}>
        <span className={'text-right text-lg font-semibold'}>
          Классное руководство
        </span>
      </div>

      {availabilities.boss.map(item => (
        <Card key={item.id} className={'p-4 flex flex-col gap-4'}>
          <span className={'text-lg font-semibold'}>{item.title}</span>

          <div className={'flex flex-col gap-2'}>
            <div className={'flex flex-row justify-between'}>
              <span className={'font-semibold'}>Студентов</span>
              <span className={'text-neutral-600 dark:text-neutral-300'}>
                23
              </span>
            </div>

            <div className={'flex flex-row justify-between'}>
              <span className={'font-semibold'}>На больничном</span>
              <span className={'text-neutral-600 dark:text-neutral-300'}>
                16
              </span>
            </div>

            <div className={'flex flex-row justify-between'}>
              <span className={'font-semibold'}>На ИУП</span>
              <span className={'text-neutral-600 dark:text-neutral-300'}>
                23
              </span>
            </div>
          </div>

          {result?.permissions?.includes(
            PermissionType.JOURNAL_MODULE_PREVIEW,
          ) && (
            <Button
              onClick={() =>
                navigate(`/journals/items/subject/?groupId=${item.id}`, {})
              }
            >
              Открыть журнал
            </Button>
          )}
        </Card>
      ))}
    </div>
  );
};
