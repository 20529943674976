import { PlanEditorHeader } from './plan-editor-header.component';
import { PlanEditorContent } from './content/plan-editor-content.component';
import { PlanEditorMeta } from './meta/plan-editor-meta.component';
import { usePlanEditor } from '../../context/plan-editor.context';
import { CardLoader } from '../../../common/component/loading/card-loader.component';
import { PlanEditorDeleteCategoryModal } from './modal/plan-editor-delete-category-modal.component';
import { PlanEditorDeleteItemModalComponent } from './modal/plan-editor-delete-item-modal.component';
import { PlanEditorDeleteModalComponent } from './modal/plan-editor-delete-modal.component';
import { PlanEditorExportModalComponent } from './modal/plan-editor-export-modal.component';
import { PlanEditorImportModalComponent } from './modal/plan-editor-import-modal.component';
import { PlanEditorVerificationModal } from './modal/plan-editor-verification-modal.component';
import { PlanEditorAcceptModalComponent } from './modal/plan-editor-accept-modal.component';
import { PlanEditorDeclineModalComponent } from './modal/plan-editor-decline-modal.component';

export const PlanEditor = () => {
  const { isLoading } = usePlanEditor();

  return (
    <div className={'flex flex-col gap-4'}>
      <PlanEditorHeader />

      {isLoading ? (
        <CardLoader />
      ) : (
        <div className={'relative flex flex-row gap-4 w-full'}>
          <div className={'w-9/12'}>
            <PlanEditorContent />
          </div>

          <div className={'w-3/12 sticky top-20 h-[200px]'}>
            <PlanEditorMeta />
          </div>
        </div>
      )}

      <PlanEditorDeleteCategoryModal />
      <PlanEditorDeleteItemModalComponent />
      <PlanEditorDeleteModalComponent />
      <PlanEditorExportModalComponent />
      <PlanEditorImportModalComponent />
      <PlanEditorVerificationModal />
      <PlanEditorAcceptModalComponent />
      <PlanEditorDeclineModalComponent />
    </div>
  );
};
