import { ExamType, PlanItemEntity } from '../../../plan-item.entity';
import { usePlan } from '../../../../../../context/plan.context';
import { Input } from '../../../../../../../common/component/input/input.component';
import { InputSelect } from '../../../../../../../common/component/input-select/input-select';

export type PlanTableContentCategorySubjectExamProps = {
  root: PlanItemEntity;
  plan?: PlanItemEntity;
};

export const PlanTableContentCategorySubjectExam = ({
  root,
  plan,
}: PlanTableContentCategorySubjectExamProps) => {
  const { base, specialty, semesters, hourAt, updateHourAt } = usePlan();

  const years = base === 'FULL' ? specialty.fullYears : specialty.notFullYears;

  return (
    <div className={'flex flex-row w-2/4'}>
      {Array.from(Array(years).keys()).map(year => {
        const filtered = semesters.filter(item => item.year === year);

        return (
          <div key={year} className={'w-full  '}>
            <div className={`flex flex-row`}>
              {filtered.map((semester, index) => {
                const hour = plan ? hourAt(plan.id, semester.id) : undefined;

                return (
                  <div
                    key={semester.countThrough}
                    className={`flex flex-row ${
                      filtered.length === 1 ? 'w-full ' : `maxw-[128px]`
                    }`}
                  >
                    <div
                      className={
                        'flex flex-row text-center items-center justify-center border-r-2 border-[#F0F2F5] dark:border-[#333333] w-1/2'
                      }
                    >
                      <Input
                        placeholder={'0'}
                        background={false}
                        defaultValue={hour?.hours ?? 0}
                        className={'w-full h-full text-center'}
                        onKeyDown={event => {
                          if (
                            event.key !== 'Decimal' &&
                            !event.code.startsWith('Digit') &&
                            isNaN(Number(event.key)) &&
                            event.key !== 'Backspace' &&
                            !event.key.startsWith('Arrow') &&
                            event.key !== 'Enter'
                          ) {
                            event.preventDefault();
                            event.stopPropagation();
                            return;
                          }

                          if (
                            event.currentTarget.value.toString().length === 3 &&
                            event.key !== 'Backspace' &&
                            !event.key.startsWith('Arrow') &&
                            event.key !== 'Enter'
                          ) {
                            event.preventDefault();
                            event.stopPropagation();
                            return;
                          }

                          if (event.key !== 'Enter') {
                            return;
                          }

                          event.preventDefault();
                          event.stopPropagation();

                          const value = Number(event.currentTarget.value);
                          updateHourAt(semester.id, root.id, plan?.id, value);
                        }}
                        onBlur={event => {
                          const value = Number(event.target.value);
                          if (!isNaN(value)) {
                            updateHourAt(semester.id, root.id, plan?.id, value);
                          }
                        }}
                      />
                    </div>
                    <div
                      className={`flex flex-row text-center items-center justify-center w-1/2 ${
                        year + 1 === years && index + 1 === filtered.length
                          ? ''
                          : 'border-r-2 border-[#F0F2F5] dark:border-[#333333]'
                      }`}
                    >
                      <InputSelect
                        title={'X'}
                        background={false}
                        centered={true}
                        selected={
                          hour !== undefined && hour.exam !== ExamType.NONE
                            ? hour.exam
                            : undefined
                        }
                        items={[
                          {
                            title: 'X',
                            value: ExamType.NONE,
                          },

                          { title: 'Э', value: ExamType.EXAM },
                          { title: 'И', value: ExamType.OFFSET },
                          {
                            title: 'З',
                            value: ExamType.DIFFERENTIAL_OFFSET,
                          },
                        ]}
                        onChange={value =>
                          updateHourAt(
                            semester.id,
                            root.id,
                            plan?.id,
                            undefined,
                            value as ExamType,
                          )
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
