import { api } from '../common/app/networking/axios';
import {
  CreateNewsDTO,
  CreatePollDTO,
  NewsEntity,
  NewsEntityValues,
  NewsReactions,
  PollEntity,
  ReactionTypeEntity,
} from './news.entity';

export const findAll = (offset: number, limit: number) =>
  api.get<NewsEntity[]>('/news/', {
    params: {
      offset,
      limit,
    },
  });

export const createItem = (data: CreateNewsDTO) => api.post('news/', data);

export const updateItem = (id: number, data: NewsEntityValues) =>
  api.put(`news/${id}`, data);

export const deleteItem = (id: number) => api.delete(`news/${id}`);

export const createPoll = (data: CreatePollDTO) =>
  api.post<PollEntity>('poll', data);

export const voteItem = (pollId: number, items: number[]) =>
  api.put<PollEntity>(`poll/${pollId}/vote`, {
    items,
  });

export const findReactions = () =>
  api.get<ReactionTypeEntity[]>('/news/reactions/');

export const setReactions = (newsId: number, type: string) =>
  api.put<NewsReactions[]>(`news/${newsId}/reactions`, { type });
