import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { SpecialityGroupEntity } from '../entity/group.entity';
import { SpecialtyGroupRelationEntity } from '../entity/group-relation.entity';
import { useMutation, useQueries } from '@tanstack/react-query';
import { SemesterEntity } from '../components/plan/semester/semester.entity';
import {
  StatementEntity,
  StatementUnsavedEntity,
} from '../entity/statement.entity';
import { useList } from 'react-use';
import { PlanItemEntity } from '../components/plan/table/plan-item.entity';
import { useNotifier } from '../../common/app/notification/notification-context';
import { createOne, findOne, getOne } from '../../common/entity/entity.service';

export type SpecialtyGroupStatementsContextType = {
  semesterId: number | undefined;
  setSemesterId: (value: number | undefined) => void;

  isEditing: boolean;
  setIsEditing: (value: boolean) => void;

  statements: StatementEntity[];

  group: SpecialityGroupEntity | undefined;
  relations: SpecialtyGroupRelationEntity | undefined;
  plans: PlanItemEntity[];
  semesters: SemesterEntity[];

  statementAt: (subjectId: number, studentId: number) => number | undefined;
  update: (subjectId: number, studentId: number, mark: number) => void;
  save: () => void;
};

export const SpecialtyGroupStatementsContext =
  createContext<SpecialtyGroupStatementsContextType>(
    {} as SpecialtyGroupStatementsContextType,
  );

export type SpecialtyGroupStatementsProps = {
  groupId?: string;
};

export const SpecialtyGroupStatementsProvider = ({
  children,
  groupId,
}: PropsWithChildren & SpecialtyGroupStatementsProps) => {
  const { notify } = useNotifier();

  const [semesterId, setSemesterId] = useState<number | undefined>(undefined);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [toSave, { push, filter, clear }] = useList<StatementUnsavedEntity>();

  const [group, relations] = useQueries({
    queries: [
      {
        queryKey: ['group', groupId],
        queryFn: () =>
          findOne<SpecialityGroupEntity>('groups', Number(groupId)).then(
            response => response.data,
          ),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['relations', groupId],
        queryFn: () =>
          getOne<SpecialtyGroupRelationEntity>(
            `/groups/${groupId}/relations`,
          ).then(response => response.data),
        refetchOnWindowFocus: false,
      },
    ],
  });

  const [semesters] = useQueries({
    queries: [
      {
        queryKey: ['semesters', group],
        queryFn: () =>
          getOne<SemesterEntity[]>(
            `specialties/${group.data?.specialtyId}/academic/${group.data?.startYear}/${group.data?.base}/semesters`,
          ).then(response => response.data),
        enabled: group.data !== undefined,
        refetchOnWindowFocus: false,
      },
    ],
  });

  const [plans, statements] = useQueries({
    queries: [
      {
        queryKey: ['plans', groupId, semesterId, semesters],
        queryFn: () =>
          getOne<PlanItemEntity[]>(
            `academic/groups/${groupId}/statements/${semesterId}/plans`,
          ).then(response => response.data),
        enabled: !!semesterId,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['statements', groupId, semesterId],
        queryFn: () =>
          getOne<StatementEntity[]>(
            `academic/groups/${groupId}/statements/${semesterId}/items`,
          ).then(response => response.data),
        enabled: !!semesterId,
        refetchOnWindowFocus: false,
      },
    ],
  });

  const update = useCallback(
    (subjectId: number, studentId: number, mark: number) => {
      filter(
        item => !(item.subjectId === subjectId && item.studentId === studentId),
      );

      push({
        subjectId,
        studentId,
        mark,
      });
    },
    [toSave, push, filter],
  );

  const saveMutation = useMutation({
    mutationKey: ['save', toSave, groupId, semesterId],
    mutationFn: () =>
      createOne(`academic/groups/${groupId}/statements/${semesterId}`, toSave),
    onSuccess: () => {
      statements.refetch();
      clear();
      setIsEditing(false);
      notify('success', 'Ведомость успешно сохранена!');
    },
    onError: () => notify('error', 'Не удалось сохранить ведомость.'),
  });

  const statementAt = useCallback(
    (subjectId: number, studentId: number) => {
      const cachedStatement = toSave.find(
        statement =>
          statement.subjectId === subjectId &&
          statement.studentId === studentId,
      );

      if (cachedStatement) {
        return cachedStatement.mark;
      }

      return (
        (statements.data ?? []).find(
          statement =>
            statement.subjectId === subjectId &&
            statement.studentId === studentId,
        )?.mark ?? undefined
      );
    },
    [toSave, statements],
  );

  const value = useMemo(
    () => ({
      semesterId,
      setSemesterId,

      isEditing,
      setIsEditing,

      group: group.data,
      relations: relations.data,
      plans: plans.data ?? [],
      semesters: semesters.data ?? [],
      statements: statements.data ?? [],

      statementAt,
      update,
      save: saveMutation.mutate,
    }),
    [
      semesterId,
      setSemesterId,
      isEditing,
      setIsEditing,
      group,
      relations,
      plans,
      semesters,
      statements,

      statementAt,
      update,
      saveMutation,
    ],
  );

  return (
    <SpecialtyGroupStatementsContext.Provider value={value}>
      {children}
    </SpecialtyGroupStatementsContext.Provider>
  );
};

export const useSpecialtyGroupStatements = () =>
  useContext<SpecialtyGroupStatementsContextType>(
    SpecialtyGroupStatementsContext,
  );
