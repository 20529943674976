import {
  JournalCalendarPlanThemeEntity,
  JournalPlanItemType,
} from '../../../entity/plan/journal-calendar-plan.item.entity';
import { usePlanEditor } from '../../../context/plan-editor.context';
import { Input } from '../../../../common/component/input/input.component';
import { InputSelect } from '../../../../common/component/input-select/input-select';
import { DateTime } from 'luxon';
import { firstLetterUppercase } from '../../../../common/utils/string.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../../../common/component/button/button.component';

export type PlanEditorContentItemProps = {
  categoryId: number;
  item: JournalCalendarPlanThemeEntity;
  index: number;
};

export const values: Record<JournalPlanItemType, string> = {
  BUSINESS_GAME: 'Бизнес игра',
  SOLVING_PRODUCTION_SITUATIONS: 'Решение производственных проблем',
  CONFERENCE: 'Конференция',
  CONSULTATION: 'Консультация',
  CONTROL_WORK: 'Контрольная работа',
  COURSE_DESIGN: 'Курсовое проектирование',
  COURSE_WORK: 'Курсовая работа',
  DISPUTE: 'Диспут',
  EXCURSION: 'Экскурсия',
  LAB_WORK: 'Лабораторная работа',
  LECTURE: 'Лекция',
  LESSON: 'Урок',
  PRACTICAL: 'Практика',
  PRACTICAL_WORK: 'Практическая работа',
  SELF_WORK: 'Самостоятельная работа',
  SEMINAR: 'Семинар',
};

export const PlanEditorContentItem = ({
  categoryId,
  item,
  index,
}: PlanEditorContentItemProps) => {
  const { readonly, updateItem, removeItem } = usePlanEditor();

  return (
    <tr key={item.id} className={'text-left text-black dark:text-white'}>
      <td className={'py-2 whitespace-nowrap w-10 text-center'}>
        {index + 1}.
      </td>
      <td className={'py-2 pr-4 whitespace-nowrap'}>
        <Input
          background={false}
          placeholder={'Тема'}
          defaultValue={item.title}
          className={'w-full'}
          disabled={readonly}
          onManipulated={title =>
            updateItem(categoryId, item.id, {
              title,
            })
          }
        />
      </td>

      <td className={'py-2 pr-4 whitespace-nowrap'}>
        <Input
          background={false}
          placeholder={'Часы'}
          defaultValue={item.hours}
          type={'number'}
          className={
            'max-w-[80px] text-center [appearance:textfield]  [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
          }
          disabled={readonly}
          onManipulated={hours =>
            updateItem(categoryId, item.id, {
              hours: Number(hours),
            })
          }
        />
      </td>

      <td className={'py-2 pr-4 whitespace-nowrap'}>
        <InputSelect
          background={false}
          title={'Месяц'}
          selected={item.month}
          items={Array.from(Array(12).keys())
            .filter(value => value < 6 || value >= 8)
            .map(value => ({
              value: value + 1,
              title: firstLetterUppercase(
                DateTime.now()
                  .set({ month: value + 1 })
                  .toFormat('LLLL'),
              ),
            }))}
          onChange={value =>
            updateItem(categoryId, item.id, {
              month: Number(value),
            })
          }
        />
      </td>

      <td className={'py-2 pr-4 whitespace-nowrap'}>
        <InputSelect
          background={false}
          title={'Вид'}
          selected={item.type}
          items={Object.keys(JournalPlanItemType).map(value => ({
            value,
            title: values[value as JournalPlanItemType],
          }))}
          onChange={value =>
            updateItem(categoryId, item.id, {
              type: value as JournalPlanItemType,
            })
          }
        />
      </td>

      <td className={'py-2 pr-4 whitespace-nowrap'}>
        <Input
          background={false}
          placeholder={'Пособия'}
          defaultValue={item.visual}
          className={'w-full'}
          disabled={readonly}
          onManipulated={visual =>
            updateItem(categoryId, item.id, {
              visual,
            })
          }
        />
      </td>

      <td className={'py-2 pr-4 whitespace-nowrap'}>
        <Input
          background={false}
          placeholder={'Задания'}
          defaultValue={item.homework}
          className={'w-full'}
          disabled={readonly}
          onManipulated={homework =>
            updateItem(categoryId, item.id, {
              homework,
            })
          }
        />
      </td>

      <td className={'py-2 pr-4 whitespace-nowrap'}>
        <Input
          background={false}
          placeholder={'Примечание'}
          defaultValue={item.note}
          className={'w-full'}
          disabled={readonly}
          onManipulated={note =>
            updateItem(categoryId, item.id, {
              note,
            })
          }
        />
      </td>

      {!readonly && (
        <td className={'py-2 px-4 flex flex-row gap-2'}>
          <Button
            color={'red'}
            small={true}
            onClick={() => removeItem(categoryId, item.id)}
            square={true}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </td>
      )}
    </tr>
  );
};
