import { useBuildingTime } from '../../context/time.context';
import { Card } from '../../../common/block/card.component';
import { TabList } from '../../../common/component/tab/tab-list/tab-list.component';
import { TabItem } from '../../../common/component/tab/tab-item/tab-item.component';
import { TimeType, typeTitle } from '../../type/time.type';
import { Button } from '../../../common/component/button/button.component';

export const Header = () => {
  const { building, save } = useBuildingTime();

  return (
    <Card className={'p-4 flex flex-row justify-between items-center'}>
      <div className={'flex flex-col gap-1'}>
        <div className={'text-lg font-bold'}>{building?.title}</div>
        <div className={'text-md font-medium'}>Расписание звонков</div>
      </div>
      <div className={'flex flex-row gap-2'}>
        <TabList>
          {Object.keys(typeTitle).map((key, index) => (
            <TabItem
              key={key}
              name={index}
              title={typeTitle[key as TimeType]}
            />
          ))}
          <TabItem name={3} title={'Даты'} />
        </TabList>
        <Button color={'green'} onClick={save}>
          Сохранить
        </Button>
      </div>
    </Card>
  );
};
