import { SpecialityGroupEntity } from '../../entity/group.entity';
import { BuildingEntity } from '../../../building/entity/building.entity';
import { useSpecialtyGroup } from '../../context/group.context';
import { SpinningLoader } from '../../../common/component/loading/spinning-loader.component';
import { UserEntity } from '../../../user/user.entity';
import { useAuth } from '../../../common/app/auth/auth.context';
import { EntityModule } from '../../../common/entity';

export const GroupTable = () => {
  const { result } = useAuth();
  const { specialty } = useSpecialtyGroup();

  if (!specialty) {
    return <SpinningLoader center />;
  }

  return (
    <EntityModule<SpecialityGroupEntity>
      title={`${specialty.title} - Обучаемые группы`}
      route={'groups'}
      columns={[
        {
          key: 'title',
          title: 'Название',
          input: {
            type: 'text',
            options: {
              required: 'Данное поле обязательно',
            },
          },
          render: item => item.title,
        },
        {
          key: 'startYear',
          title: 'Год начала обучения',
          input: {
            type: 'number',
            options: {
              required: 'Данное поле обязательно',
            },
          },
          render: item => item.startYear,
        },
        {
          key: 'type',
          title: 'Тип',
          input: {
            type: 'select',
            render: true,
            items: [
              {
                value: 'FREE',
                title: 'Бюджет',
              },
              {
                value: 'COMMERCIAL',
                title: 'Коммерция',
              },
              {
                value: 'ONLINE',
                title: 'Дистанционная',
              },
              {
                value: 'EXTRAMURAL',
                title: 'Заочное',
              },
              {
                value: 'SCHOOL',
                title: 'Школа',
              },
            ],
            options: {
              required: 'Данное поле обязательно',
            },
          },
        },
        {
          key: 'base',
          title: 'Уровень образования',
          input: {
            type: 'select',
            render: true,
            items: [
              {
                value: 'FULL',
                title: '11 классов',
              },
              {
                value: 'NOT_FULL',
                title: '9 классов',
              },
            ],
            options: {
              required: 'Данное поле обязательно',
            },
          },
        },
        {
          key: 'buildingId',
          title: 'Корпус',
          input: {
            type: 'service',
            route: 'buildings',
            titleFor: item => (item as BuildingEntity)?.title,
          },
          render: item => item.building?.title ?? 'Не привязан',
        },
        {
          key: 'bossId',
          title: 'Классный руководитель',
          input: {
            type: 'service',
            route: 'users',
            titleFor: item => (item as UserEntity)?.pretty,
          },
          render: item => item.building?.title ?? 'Не привязан',
        },
      ]}
      links={
        result?.user.isAdmin
          ? [
              {
                title: 'Ведомость',
                color: 'blue',
                link: item =>
                  `/specialties/${specialty.id}/groups/${item.id}/statements/`,
              },
              {
                title: 'Журнал',
                color: 'blue',
                link: item =>
                  `/specialties/${specialty.id}/groups/${item.id}/journal/`,
              },
            ]
          : [
              {
                title: 'Ведомость',
                color: 'blue',
                link: item =>
                  `/specialties/${specialty.id}/groups/${item.id}/statements/`,
              },
              {
                title: 'Связи',
                color: 'green',
                link: item =>
                  `/specialties/${specialty.id}/groups/${item.id}/relations/`,
              },
            ]
      }
      request={{
        post: {
          specialtyId: specialty.id,
        },
        get: {
          specialtyId: specialty.id,
        },
      }}
    />
  );
};
