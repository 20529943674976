import { ScheduleContext } from '../../schedule.context';
import { Modal } from '../../../common/component/modal/modal';
import { InputSelect } from '../../../common/component/input-select/input-select';
import { AutocompleteService } from '../../../common/component/autocomplete-service/autocomplete-service.component';
import { BuildingEntity } from '../../../building/entity/building.entity';
import { useContextSelector } from 'use-context-selector';

export const ScheduleBottomSettingsModal = () => {
  const modal = useContextSelector(ScheduleContext, context => context.modal);

  const setModal = useContextSelector(
    ScheduleContext,
    context => context.setModal,
  );

  const lessonSize = useContextSelector(
    ScheduleContext,
    context => context.lessonSize,
  );

  const setLessonSize = useContextSelector(
    ScheduleContext,
    context => context.setLessonSize,
  );

  const setBuildingId = useContextSelector(
    ScheduleContext,
    context => context.setBuildingId,
  );

  const buildingId = useContextSelector(
    ScheduleContext,
    context => context.buildingId,
  );

  return (
    <Modal
      showed={modal.type === 'settings'}
      title={'Клонирование учебных планов'}
      onClose={() =>
        setModal({
          type: 'none',
        })
      }
    >
      <div className={'flex flex-col gap-4'}>
        <InputSelect
          title={'Размер занятий'}
          selected={lessonSize}
          items={[
            {
              value: 8,
              title: 'Маленький',
            },
            {
              value: 10,
              title: 'Средний',
            },
            {
              value: 12,
              title: 'Большой',
            },
          ]}
          onChange={value => setLessonSize(Number(value))}
        />

        <AutocompleteService<BuildingEntity>
          title={'Корпус'}
          route={'buildings'}
          onSelected={value => setBuildingId(Number(value))}
          selected={buildingId}
          mapper={item => ({
            value: item.id,
            title: item.title,
          })}
        />
      </div>
    </Modal>
  );
};
