import { useSpecialtyGroupStatements } from '../../../../context/group-statements.context';
import { StatementBodyStudentsItem } from './statement-body-students-item.component';

export const StatementBodyStudents = () => {
  const { relations } = useSpecialtyGroupStatements();

  return (
    <div className={'flex flex-col'}>
      <div
        className={'w-96 h-48 border-r border-[#F0F2F5] dark:border-[#333333]'}
      />
      {relations?.students?.map((item, index) => (
        <StatementBodyStudentsItem key={item.id} index={index} user={item} />
      ))}
    </div>
  );
};
