import { JournalContent } from '../components/items/journal-content.component';
import { JournalItemsProvider } from '../context/items.context';

export const JournalItemsModule = () => {
  return (
    <JournalItemsProvider>
      <div className={'flex flex-col gap-4'}>
        <JournalContent />
      </div>
    </JournalItemsProvider>
  );
};
