import { Dropdown } from '../../../common/component/dropdown/dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useNews } from '../../news.context';
import { NewsEntity } from '../../news.entity';
import { Fragment } from 'react';
import { Profile } from '../../../common/component/profile/profile.component';
import { Verification } from '../../../common/component/verification/verification.component';
import { DropdownItem } from '../../../common/component/dropdown/dropdown-item';
import { useAuth } from '../../../common/app/auth/auth.context';
import { PermissionType } from '../../../common/app/auth/auth-role-permission.type';

export type NewsHeaderProps = {
  item: NewsEntity;
};

export const NewsHeader = ({ item }: NewsHeaderProps) => {
  const { result } = useAuth();
  const { setModal } = useNews();

  return (
    <div className={'flex flex-row justify-between place-items-center'}>
      <div className={'flex flex-row gap-2'}>
        {item.header.image ? (
          <img className={'h-12 w-12 rounded-xl'} src={item.header.image} />
        ) : item.header.author ? (
          <Profile user={item.header.author} title={false} size={12} />
        ) : (
          <div
            className={
              'h-12 w-12 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-xl flex justify-center items-center'
            }
          >
            <div className={'font-bold text-lg'}>К</div>
          </div>
        )}

        <div className={'flex flex-col'}>
          <div>{item.header.title}</div>
          <div
            className={'flex flex-row items-center gap-2 dark:text-gray-400'}
          >
            {item.header.author ? (
              <Fragment>
                <div>{item.header.author.pretty}</div>
                {item.header.author.isAdmin && <Verification />}
                {item.header.author.title && (
                  <div className={'flex flex-row gap-2 items-center'}>
                    <div>•</div>
                    <div>{item.header.author.title}</div>
                  </div>
                )}
              </Fragment>
            ) : (
              <div className={'dark:text-gray-400'}>
                Образовательная организация
              </div>
            )}
          </div>
        </div>
      </div>
      {result?.permissions?.includes(PermissionType.NEWS_CREATE) && (
        <Dropdown
          header={
            <FontAwesomeIcon
              icon={faEllipsisVertical}
              className={'h-6 w-6 text-[#B2B2B2]'}
            />
          }
          headerStyled={false}
        >
          <DropdownItem
            title={'Редактировать'}
            color={'yellow'}
            onClick={() => setModal({ type: 'update', item })}
          />
          <DropdownItem
            title={'Удалить'}
            color={'red'}
            onClick={() => setModal({ type: 'delete', item })}
          />
        </Dropdown>
      )}
    </div>
  );
};
