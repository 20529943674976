import { useParams } from 'react-router-dom';
import React from 'react';
import { BuildingTimeProvider } from '../context/time.context';
import { BuildingTimeContent } from '../components/time/content.component';

export type BuildingTimeParams = {
  buildingId: string;
};

export const BuildingTimeModule = () => {
  const { buildingId } = useParams<BuildingTimeParams>();

  return (
    <BuildingTimeProvider buildingId={buildingId}>
      <BuildingTimeContent />
    </BuildingTimeProvider>
  );
};
