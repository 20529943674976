import { Card } from '../../../common/block/card.component';
import { NavLink } from 'react-router-dom';

export const PlanEditorHeader = () => {
  return (
    <Card className={'p-4 flex flex-row justify-between w-3/12'}>
      <span className={'font-bold text-lg'}>
        <NavLink to={'/journals/plan/'}>
          Календарно-тематическое планирование
        </NavLink>{' '}
        » Редактирование плана
      </span>
    </Card>
  );
};
