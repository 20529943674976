import { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { GroupRelationType } from '../../entity/group-relation.entity';
import { useSpecialtyGroupRelation } from '../../context/group-relation.context';
import { Card } from '../../../common/block/card.component';
import { Autocomplete } from '../../../common/component/autocomplete/autocomplete.component';
import { Profile } from '../../../common/component/profile/profile.component';
import { AutocompleteService } from '../../../common/component/autocomplete-service/autocomplete-service.component';
import { UserEntity } from '../../../user/user.entity';

export type RelationTableProps = {
  title: string;
  type: GroupRelationType;
};

export const RelationTable = ({ title, type }: RelationTableProps) => {
  const [search, setSearch] = useState('');
  const { relations, setRelation, dropRelation } = useSpecialtyGroupRelation();

  const items = useMemo(
    () =>
      type === GroupRelationType.STUDENT
        ? relations.students
        : type === GroupRelationType.CURATOR
        ? relations.curators
        : relations.captains,
    [relations],
  );

  return (
    <Card className={'flex flex-col gap-6 p-4'}>
      <div className={'flex flex-row place-items-center justify-between gap-1'}>
        <div className={'text-lg font-bold'}>{title}</div>

        <div className={'w-96'}>
          {type === GroupRelationType.CAPTAIN ? (
            <Autocomplete
              title={'Студент группы'}
              items={relations.students
                .filter(item => item.full.includes(search))
                .map(item => ({
                  title: item.pretty,
                  value: item.id,
                  view: <Profile user={item} size={6} />,
                }))}
              onSelected={value => setRelation(value as number, type)}
              onSearch={value => setSearch(value)}
              selected={undefined}
              disabled={relations.captains.map(item => item.id)}
              selectedOnce={true}
            />
          ) : (
            <AutocompleteService<UserEntity>
              title={'Студент'}
              route={'/users/'}
              selectedOnce={true}
              disabled={(type === GroupRelationType.STUDENT
                ? relations.students
                : relations.curators
              ).map(item => item.id)}
              onSelected={value => setRelation(value as number, type)}
              mapper={item => ({
                value: item.id,
                title: item.pretty,
                view: <Profile user={item} size={6} />,
              })}
            />
          )}
        </div>
      </div>

      <div
        className={
          'flex flex-col divide-y divide-gray-200 dark:divide-stone-900'
        }
      >
        {items.length > 0
          ? items.map((item, index) => (
              <div
                key={item.id}
                className={
                  'flex flex-row place-items-center justify-between py-2'
                }
              >
                <Profile
                  prefix={<span className={'w-4 mr-2'}>{index + 1}. </span>}
                  user={item}
                  size={8}
                />
                <button onClick={() => dropRelation(item.id, type)}>
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </div>
            ))
          : 'Студенты отсутствуют'}
      </div>
    </Card>
  );
};
