import { Card } from '../../../../common/block/card.component';
import { usePlanEditor } from '../../../context/plan-editor.context';

export const PlanEditorContentEmpty = () => {
  const { addCategory, plan } = usePlanEditor();

  return (
    <button className={'w-full '} onClick={() => addCategory()}>
      <Card
        className={
          'p-4  w-full flex flex-col gap-4 hover:bg-opacity-80 text-center'
        }
      >
        {plan.categories.length === 0 && <span>Категории отсутствуют</span>}

        <span className={'text-neutral-300'}>Создать новую?</span>
      </Card>
    </button>
  );
};
