import { Card } from '../../../../common/block/card.component';
import { PlanTableHeader } from './header/plan-table-header.component';
import { PlanTableContent } from './content/plan-table-content.component';

export const PlanTable = () => {
  return (
    <Card className={'flex flex-col'}>
      <PlanTableHeader />
      <PlanTableContent />
    </Card>
  );
};
