import { api } from '../../common/app/networking/axios';
import { MetaTypeEntity } from '../../common/networking/entity/meta-type.entity';
import { JournalCalendarPlanItemEntity } from '../entity/plan/journal-calendar-plan.item.entity';
import { JournalPlanItemEntity } from '../entity/plan/journal-plan-item.entity';
import { JournalPlanMetaEntity } from '../entity/plan/journal-plan-meta.entity';

export const getPlans = (
  params: object,
): Promise<MetaTypeEntity<JournalPlanMetaEntity, JournalPlanItemEntity>> =>
  api
    .get('/journal/plan/all/', {
      params: {
        ...params,
      },
    })
    .then(response => response.data);

export const createPlan = (
  specialtyId: number,
  subjectId: number,
  revision: number,
  year: number,
  groupId: number,
): Promise<JournalCalendarPlanItemEntity> =>
  api
    .post('/journal/plan/', {
      specialtyId,
      subjectId,
      revision,
      year,
      groupId,
    })
    .then(response => response.data);
