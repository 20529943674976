import { useSpecialtyGroupStatements } from '../../../../context/group-statements.context';
import { StatementBodySubjectsItem } from './statement-body-subjects-item.component';

export const StatementBodySubjects = () => {
  const { plans } = useSpecialtyGroupStatements();

  return (
    <div className={'flex flex-row'}>
      {plans.map(plan => (
        <StatementBodySubjectsItem key={plan.id} item={plan} />
      ))}
    </div>
  );
};
