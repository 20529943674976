import { Control, Controller } from 'react-hook-form';
import { registerLocale } from 'react-datepicker';

import {
  EntityColumnType,
  EntityServiceColumnType,
  EntityType,
} from '../../../entity.type';

import ru from 'date-fns/locale/ru';

import { ReactNode } from 'react';
import { AutocompleteService } from '../../../../component/autocomplete-service/autocomplete-service.component';
registerLocale('ru', ru);

export type EntityServiceTitleForType = <ItemType>(value: ItemType) => string;
export type EntityServiceViewForType = <ItemType>(value: ItemType) => ReactNode;

export type EntityServiceFieldProps<ItemType> = {
  column: EntityColumnType<ItemType>;
  control: Control;
  input: EntityServiceColumnType;
  error?: string;
};

export const EntityServiceField = <ItemType,>({
  column,
  control,
  input,
}: EntityServiceFieldProps<EntityType<ItemType>>) => (
  <Controller
    name={column.key}
    control={control}
    rules={input.options}
    render={field => (
      <AutocompleteService<EntityType<ItemType>>
        title={column.title}
        route={input.route}
        selected={field.field.value}
        onSelected={value => field.field.onChange(value)}
        mapper={item => ({
          value: item.id,
          title: input.titleFor(item),
          ...(input.viewFor && { view: input.viewFor(item) }),
        })}
      />
    )}
  />
);
