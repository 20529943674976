import { forwardRef, InputHTMLAttributes, LegacyRef, useState } from 'react';
import { ErrorCard } from '../../block/error-card.component';

export type InputProps = {
  background?: boolean;
  error?: string;

  onManipulated?: (content: string) => void;
};

export const Input = forwardRef(
  (
    props: InputHTMLAttributes<HTMLInputElement> & InputProps,
    ref: LegacyRef<HTMLInputElement>,
  ) => {
    const [message, setMessage] = useState('');

    return (
      <div className={'w-full'}>
        <input
          ref={ref}
          {...props}
          {...(props.onManipulated
            ? {
                onChange: event => {
                  setMessage(event.target.value ?? '');
                },
                onKeyDown: event => {
                  if (event.key === 'Enter') {
                    props.onManipulated?.(message);
                  }
                },
                onBlur: () => {
                  props.onManipulated?.(message);
                },
              }
            : {})}
          className={`${
            props.background == true
              ? 'w-full rounded-lg text-md bg-[#F0F2F5] border border-[#D3D5D7] focus:border-[#3375f6] dark:bg-[#333333] dark:border-none dark:text-white dark:focus:border-[#3375f6] dark:placeholder-[#e1e3e6]'
              : `border-none  rounded-lg w-full dark:bg-[#333333] bg-[#F0F2F5]   ${
                  !props.disabled &&
                  'hover:bg-[#e4e6e9] h-full hover:dark:bg-[#444444] hover:cursor-pointer'
                } focus:cursor-text focus:bg-[#F0F2F5] focus:dark:bg-[#333333]`
          } ${props.className} lg:px-4 lg:py-2`}
        />

        {props.error && <ErrorCard error={props.error} />}
      </div>
    );
  },
);

Input.displayName = 'Input';
