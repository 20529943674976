import { UserEntity } from '../../../../user/user.entity';
import { SubjectEntity } from '../../../../subject/subject.entity';
import { SpecialtyEntity } from '../../../entity/specialty.entity';
import { AcademicBase } from '../../../academic-base.enum';
import { ClassroomEntity } from '../../../../building/entity/classroom.entity';

export type PlanItemEntity = {
  id: number;

  specialtyId: number;
  revision: number;
  base: AcademicBase;
  rootId?: number;
  rootTitle: string;
  subjectId?: number;
  teacherId?: number;
  classroomId?: number;

  createdAt: number;

  specialty: SpecialtyEntity;

  root?: PlanItemEntity;

  children: PlanItemEntity[];

  subject: SubjectEntity;
  teacher: UserEntity;
  classroom: ClassroomEntity;
};

export enum ExamType {
  NONE = 'NONE',
  EXAM = 'EXAM',
  OFFSET = 'OFFSET',
  DIFFERENTIAL_OFFSET = 'DIFFERENTIAL_OFFSET',
}

export type PlanItemHourEntity = {
  id?: number;
  planId: number;
  semesterId: number;
  semester?: PlanItemSemesterEntity;
  hours: number;
  exam: ExamType;
};

export type PlanItemSemesterEntity = {
  id: number;
  revision: number;
  year: number;
  start: number;
  end: number;
};
