import { Card } from '../../../../common/block/card.component';
import { usePlan } from '../../../context/plan.context';
import { Button } from '../../../../common/component/button/button.component';
import {
  Badge2,
  BadgeColorVariant2,
} from '../../../../common/component/badge2/badge';
import { DateTime } from 'luxon';
import { NavLink } from 'react-router-dom';
import { convertToRoman } from '../../../../common/utils/number.utils';

const statusTranslations = {
  NOT_STARTED: 'Не начиналось',
  IN_PROGRESS: 'В процессе разработки',
  REVIEW_REQUESTED: 'Требуется проверка',
  DECLINED: 'Требуются правки',
  REVIEWED: 'Проверено',
};

const statusColors = {
  NOT_STARTED: 'red',
  IN_PROGRESS: 'yellow',
  REVIEW_REQUESTED: 'purple',
  DECLINED: 'red',
  REVIEWED: 'green',
};

export const ItemsTab = () => {
  const { results, create } = usePlan();

  if (!results || (results.items?.length ?? 0) === 0) {
    return (
      <Card className={'flex flex-col gap-4 p-4'}>
        <div
          className={
            'font-bold h-full flex flex-col justify-center items-center'
          }
        >
          Данные по заданному запросу отсутсвуют..
        </div>
      </Card>
    );
  }

  return (
    <Card className={'flex flex-col gap-4 p-4'}>
      <table>
        <thead className={'bg-[#F0F2F5] dark:bg-[#333333]'}>
          <tr
            className={
              'text-left font-bold text-black dark:text-white whitespace-nowrap'
            }
          >
            <th className={'rounded-l-lg py-2 px-4 w-1/12'}>Группа</th>
            <th className={'py-2 p-4 w-24'}>Дисциплина</th>
            <th className={'py-2 px-4'}>Разработчик</th>
            <th className={'py-2 px-4'}>Нагрузка</th>
            <th className={'rounded-r-lg py-2 pl-4'}>Учебный план</th>
          </tr>
        </thead>

        {results?.items?.map(item => {
          return (
            <tr
              key={item.id}
              className={'text-left text-black dark:text-white'}
            >
              <td className={'py-2 px-4 whitespace-nowrap w-1/12'}>
                {item.group.title.replaceAll(' ', '').toUpperCase()}
              </td>
              <td className={'py-2 px-4 w-5/12'}>{item.plan.subject.title}</td>
              <td className={'py-2 px-4 w-3/12'}>{item.teacher.pretty}</td>
              <td className={'py-2 px-4 w-2/12'}>
                {item.plan.hours
                  .sort(
                    (first, second) =>
                      (first.semester?.start || 0) -
                      (second.semester?.start || 0),
                  )
                  .map(hour => {
                    const semesterIndex = item.group.specialty?.semesters
                      ?.sort(
                        (first, second) =>
                          (first?.start || 0) - (second?.start || 0),
                      )
                      ?.findIndex(
                        semester => semester.id === hour.semester?.id,
                      );

                    return `${hour.hours} ч. ${
                      semesterIndex !== -1 && semesterIndex !== undefined
                        ? `(${convertToRoman(semesterIndex + 1)} сем.)`
                        : ''
                    }`;
                  })
                  .join(' / ')}
              </td>
              <td className={'py-2 px-4 text-right w-1/12'}>
                {!item.plan.subject.plan ? (
                  <div className={'flex flex-row gap-2 items-center w-full'}>
                    <Button
                      className={'w-full'}
                      small={true}
                      color={'green'}
                      onClick={() => create(item)}
                    >
                      Создать
                    </Button>
                  </div>
                ) : (
                  <NavLink
                    to={`/journals/plans/${item.plan.subject.plan.id}/editor`}
                  >
                    <div
                      className={
                        'flex flex-col gap-2 bg-[#F0F2F5] dark:bg-[#333333] p-2 whitespace-nowrap rounded-lg text-left'
                      }
                    >
                      <Badge2
                        classname={'w-full'}
                        color={
                          statusColors[
                            item.plan.subject.plan.status?.status
                          ] as BadgeColorVariant2
                        }
                      >
                        Статус:{' '}
                        {
                          statusTranslations[
                            item.plan.subject.plan.status?.status
                          ]
                        }
                      </Badge2>
                      <span className={'text-right text-neutral-300'}>
                        {DateTime.fromSeconds(
                          item.plan.subject.plan.updatedAt,
                        ).toFormat('dd.MM.yyyy г.')}
                      </span>
                    </div>
                  </NavLink>
                )}
              </td>
            </tr>
          );
        })}
      </table>
    </Card>
  );
};
