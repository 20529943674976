import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SubjectEntity } from './subject.entity';
import { useList } from 'react-use';
import { UserEntity } from '../user/user.entity';
import {
  addClassification,
  findAllClassifications,
  removeClassification,
} from './subject.service';
import { useNotifier } from '../common/app/notification/notification-context';
import { findOne } from '../common/entity/entity.service';

export type SubjectEntityType = SubjectEntity | undefined;

export type SubjectContextType = {
  subject: SubjectEntityType;
  setSubject: (value: SubjectEntityType) => void;

  teachers: UserEntity[];
  addTeacher: (id: number) => void;
  removeTeacher: (id: number) => void;
};

export const SubjectContext = createContext<SubjectContextType>(
  {} as SubjectContextType,
);

export const SubjectProvider = ({ children }: PropsWithChildren) => {
  const { notify } = useNotifier();

  const [subject, setSubject] = useState<SubjectEntityType>(undefined);
  const [teachers, { push, filter, clear }] = useList<UserEntity>([]);

  useEffect(() => {
    if (!subject) {
      return;
    }

    findAllClassifications(subject.id).then(response => {
      clear();
      push(...response.data);
    });
  }, [subject, push, clear]);

  const addTeacher = useCallback(
    (id: number) => {
      if (!subject) {
        return;
      }

      addClassification(subject.id, id)
        .then(() => findOne<UserEntity>('users', id))
        .then(response => {
          push(response.data);
          notify('success', 'Классификация успешно добавлена');
        })
        .catch(() => notify('error', 'Ошибка при добавлении классификации'));
    },
    [teachers, push],
  );

  const removeTeacher = useCallback(
    (id: number) => {
      if (!subject) {
        return;
      }

      removeClassification(subject.id, id)
        .then(() => {
          filter(item => item.id !== id);
          notify('success', 'Классификация успешно удалена');
        })
        .catch(() => notify('error', 'Ошибка при удалении классификации'));
    },
    [teachers, filter],
  );

  const value = useMemo(
    () => ({
      subject,
      setSubject,

      teachers,
      addTeacher,
      removeTeacher,
    }),
    [subject, setSubject, teachers, addTeacher, removeTeacher],
  );

  return (
    <SubjectContext.Provider value={value}>{children}</SubjectContext.Provider>
  );
};

export const useSubject = () => useContext<SubjectContextType>(SubjectContext);
