import { usePlan } from '../../../../../../context/plan.context';
import { Modal } from '../../../../../../../common/component/modal/modal';
import { AutocompleteService } from '../../../../../../../common/component/autocomplete-service/autocomplete-service.component';
import { UserEntity } from '../../../../../../../user/user.entity';
import { ClassroomEntity } from '../../../../../../../building/entity/classroom.entity';
import { Button } from '../../../../../../../common/component/button/button.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Controller, useForm } from 'react-hook-form';
import { Profile } from '../../../../../../../common/component/profile/profile.component';
import { Badge } from '../../../../../../../common/component/badge/badge';
import { InputSelect } from '../../../../../../../common/component/input-select/input-select';
import { PlanItemTeacherEntity } from '../../../plan-item-teacher.entity';
import { useMemo } from 'react';

export type PlanTeacherFormValues = {
  teacherId: number | null;
  groupId: number | null;
  classroomId: number | null;
};

export const PlanTableContentCategorySubjectTeacherModal = () => {
  const { modal, setModal, itemAt, teachers, meta, addTeacher, removeTeacher } =
    usePlan();

  const { handleSubmit, setValue, control, getValues } =
    useForm<PlanTeacherFormValues>();

  const onSubmit = handleSubmit(values => {
    if (values.teacherId === null || values.groupId === null) {
      return;
    }

    addTeacher(
      values.groupId,
      values.teacherId,
      values.classroomId ?? undefined,
    );
    setValue('groupId', null);
    setValue('teacherId', null);
    setValue('classroomId', null);
  });

  const subjectId: number =
    modal.type === 'subjectTeachers'
      ? (() => {
          return itemAt(modal.rootId, modal.planId)?.subjectId ?? -1;
        })()
      : -1;

  const mappedTeachers = useMemo(
    () =>
      teachers.reduce(
        (previous: Record<number, PlanItemTeacherEntity[]>, current) => {
          (previous[current.groupId] = previous[current.groupId] || []).push(
            current,
          );

          return previous;
        },
        {},
      ),
    [teachers],
  );

  return (
    <Modal
      title={'Настройка связи преподавателей и аудитории'}
      showed={modal.type === 'subjectTeachers'}
      onClose={() =>
        setModal({
          type: 'none',
        })
      }
    >
      <div className={'flex flex-col gap-6'}>
        <div className={'flex flex-col gap-2'}>
          <div className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
            Список связей
          </div>

          <div
            className={
              'flex flex-col gap-2 divide-y divide-[#F0F2F5] dark:divide-[#333333]'
            }
          >
            {Object.keys(mappedTeachers).map(key => {
              const id = Number(key);
              const group = meta.find(item => item.id === id);
              const items = mappedTeachers[id] ?? [];

              return (
                <div key={key} className={'flex flex-col gap-2'}>
                  <span>{group?.title}</span>

                  {items.length > 0 &&
                    items.map((relation, index) => (
                      <div
                        key={index}
                        className={'flex flex-row justify-between pt-1'}
                      >
                        <Profile
                          prefix={<span>{index + 1}. </span>}
                          user={relation.teacher}
                          suffix={
                            relation.classroom ? (
                              <span>- {relation.classroom.title}</span>
                            ) : undefined
                          }
                          size={8}
                        />
                        <div>
                          <button onClick={() => removeTeacher(relation.id)}>
                            <FontAwesomeIcon icon={faXmark} />
                          </button>
                        </div>
                      </div>
                    ))}

                  {teachers.length === 0 && <div>Связи отсутствуют</div>}
                </div>
              );
            })}
          </div>
        </div>

        <form className={'flex flex-col gap-4'} onSubmit={onSubmit}>
          <div className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
            Форма добавления
          </div>

          <Controller
            name={'groupId'}
            control={control}
            render={field => (
              <InputSelect
                title={'Группа'}
                items={meta.map(group => ({
                  value: group.id,
                  title: group.title,
                }))}
                selected={field.field.value ?? undefined}
                onChange={value => field.field.onChange(Number(value))}
              />
            )}
          />

          <Controller
            name={'teacherId'}
            control={control}
            render={field => (
              <AutocompleteService<UserEntity>
                title={'Преподаватель'}
                route={`/subject/${subjectId}/classification`}
                selected={field.field.value}
                disabled={
                  getValues().groupId !== undefined
                    ? teachers
                        .filter(item => item.groupId === getValues().groupId)
                        .map(item => item.teacher.id)
                    : []
                }
                onSelected={value => field.field.onChange(value)}
                emptyTitle={'Классификации для данной дисциплины не найдены'}
                mapper={user => ({
                  value: user.id,
                  view: <Profile user={user} size={6} />,
                  title: user.pretty,
                })}
              />
            )}
          />

          <Controller
            name={'classroomId'}
            control={control}
            render={field => (
              <AutocompleteService<ClassroomEntity>
                title={'Аудитория'}
                route={'/buildings/0/classrooms/'}
                selected={field.field.value}
                onSelected={value => field.field.onChange(value)}
                mapper={classroom => ({
                  value: classroom.id,
                  title: classroom.title,
                  view: (
                    <div className={'flex flex-row justify-between'}>
                      <div>{classroom.title}</div>
                      <Badge color={'green'}>
                        Корпус: {classroom.building.title}
                      </Badge>
                    </div>
                  ),
                })}
              />
            )}
          />

          <Button color={'green'}>Добавить</Button>
        </form>
      </div>
    </Modal>
  );
};
