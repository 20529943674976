import { SpecialtyEntity } from '../entity/specialty.entity';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { startOfEducationYear } from '../../common/utils/plan.utils';
import { SemesterEntity } from '../components/plan/semester/semester.entity';
import {
  ExamType,
  PlanItemEntity,
  PlanItemHourEntity,
} from '../components/plan/table/plan-item.entity';
import { useList } from 'react-use';
import { PlanItemTeacherEntity } from '../components/plan/table/plan-item-teacher.entity';
import { SpecialityGroupEntity } from '../entity/group.entity';
import { useNotifier } from '../../common/app/notification/notification-context';
import {
  createOne,
  dropOne,
  findAll,
  putOne,
  updateOne,
} from '../../common/entity/entity.service';

export type PlanBaseValuesType = 'FULL' | 'NOT_FULL';

export type PlanSemesterType = SemesterEntity & { countThrough: number };

export type PlanModalNoneType = {
  type: 'none';
};

export type PlanModalCreateCategoryType = {
  type: 'createCategory';
  title: string;
};

export type PlanModalUpdateCategoryType = {
  type: 'updateCategory';
  id: number;
  title: string;
};

export type PlanModalDropCategoryType = {
  type: 'dropCategory';
  id: number;
  title: string;
};

export type PlanModalSubjectTeachersType = {
  type: 'subjectTeachers';
  rootId: number;
  planId: number;
};

export type PlanModalCloneType = {
  type: 'clone';
};

export type PlanModalType =
  | PlanModalNoneType
  | PlanModalCreateCategoryType
  | PlanModalUpdateCategoryType
  | PlanModalDropCategoryType
  | PlanModalSubjectTeachersType
  | PlanModalCloneType;

export type PlanContextType = {
  specialty: SpecialtyEntity;

  base: PlanBaseValuesType;
  setBase: (value: PlanBaseValuesType) => void;

  revisions: number[];
  revision: number;
  setRevision: (value: number) => void;

  modal: PlanModalType;
  setModal: (value: PlanModalType) => void;

  meta: SpecialityGroupEntity[];
  semesters: PlanSemesterType[];
  plans: PlanItemEntity[];
  teachers: PlanItemTeacherEntity[];
  groups: SpecialityGroupEntity[];

  currentTab: number;
  onTabChange: (index: number) => void;

  createCategory: () => void;
  updateCategory: () => void;
  dropCategory: () => void;

  clone: (from: number, to: number) => void;

  itemAt: (rootId: number, planId: number) => PlanItemEntity | undefined;
  createItem: (rootId: number, subjectId: number) => void;
  dropItem: (rootId: number, planId: number) => void;

  addTeacher: (
    groupId: number,
    teacherId: number,
    classroomId?: number,
  ) => void;
  removeTeacher: (id: number) => void;

  disabledSubjects: number[];

  hoursCountBySemester: (rootId: number, semesterId: number) => number;
  hoursCountByPlan: (rootId: number, planId?: number) => number;

  hourAt: (
    planId: number,
    semesterId: number,
  ) => PlanItemHourEntity | undefined;
  updateHourAt: (
    semesterId: number,
    rootId: number,
    planId?: number,
    hours?: number,
    exam?: ExamType,
  ) => void;
};

export type PlanContextProps = {
  specialty: SpecialtyEntity;
};

export const PlanContext = createContext<PlanContextType>(
  {} as PlanContextType,
);

export const PlanProvider = ({
  children,
  specialty,
}: PropsWithChildren & PlanContextProps) => {
  const { notify } = useNotifier();

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [base, setBase] = useState<PlanBaseValuesType>('FULL');
  const [revision, setRevision] = useState<number>(startOfEducationYear().year);
  const [semesters, setSemesters] = useState<PlanSemesterType[]>([]);

  const [meta, setMeta] = useState<SpecialityGroupEntity[]>([]);
  const [plans, plansActions] = useList<PlanItemEntity>([]);
  const [hours, hoursActions] = useList<PlanItemHourEntity>([]);
  const [teachers, teachersActions] = useList<PlanItemTeacherEntity>([]);
  const [groups, groupsActions] = useList<SpecialityGroupEntity>([]);

  const [modal, setModal] = useState<PlanModalType>({
    type: 'none',
  });

  useEffect(() => {
    setRevision(startOfEducationYear().year);
  }, [base]);

  useEffect(() => {
    findAll<PlanSemesterType>(
      `/specialties/${specialty.id}/academic/${revision}/${base}/semesters`,
      0,
      50,
    ).then(response =>
      setSemesters(
        response.data
          .sort((first, second) => first.start - second.start)
          .map((semester, index) => ({ ...semester, countThrough: index })),
      ),
    );

    findAll<PlanItemEntity>(
      `/specialties/${specialty.id}/academic/${revision}/${base}/items`,
      0,
      50,
    ).then(response => {
      plansActions.clear();
      plansActions.push(...response.data);
    });

    findAll<SpecialityGroupEntity>(
      `/specialties/${specialty.id}/academic/${revision}/${base}/items/meta/`,
      0,
      50,
    ).then(response => setMeta(response.data));

    findAll<PlanItemHourEntity>(
      `/specialties/${specialty.id}/academic/${revision}/${base}/hours`,
      0,
      50,
    ).then(response => {
      hoursActions.clear();
      hoursActions.push(...response.data);
    });

    findAll<SpecialityGroupEntity>('/groups/', 0, 10, undefined, {
      specialtyId: specialty.id,
      base,
      revision,
    }).then(response => {
      groupsActions.clear();
      groupsActions.push(...response.data);
    });
  }, [
    specialty,
    base,
    revision,
    currentTab,
    plansActions.clear,
    plansActions.push,
    hoursActions.clear,
    hoursActions.push,
    groupsActions.push,
    groupsActions.clear,
  ]);

  useEffect(() => {
    if (modal.type !== 'subjectTeachers') {
      return;
    }

    findAll<PlanItemTeacherEntity>(
      `specialties/${specialty.id}/academic/${revision}/${base}/teachers/${modal.planId}`,
      0,
      10,
    ).then(response => {
      teachersActions.clear();
      teachersActions.push(...response.data);
    });
  }, [specialty, base, revision, modal, teachersActions]);

  const clone = useCallback(
    (from: number, to: number) => {
      if (from === to) {
        notify('error', 'Ревизии должны отличаться!');
        return;
      }

      createOne(`/specialties/${specialty.id}/academic/manager/clone/`, {
        base,
        from,
        to,
      })
        .then(() => {
          notify('success', 'Учебный план успешно клонирован!');
          setModal({ type: 'none' });
        })
        .catch(() => notify('error', 'Ошибка клонирования учебного плана!'));
    },
    [base, specialty, setModal],
  );

  const onTabChange = useCallback(
    (index: number) => {
      setCurrentTab(index);
    },
    [setCurrentTab],
  );

  const createCategory = useCallback(() => {
    if (modal.type !== 'createCategory') {
      return;
    }

    createOne<PlanItemEntity>(
      `/specialties/${specialty.id}/academic/${revision}/${base}/items`,
      {
        rootTitle: modal.title,
      },
    )
      .then(response => {
        plansActions.push(response.data);
        notify('success', 'Категория успешно создана!');
      })
      .catch(() => notify('error', 'Ошибка при создании категории'))
      .finally(() => setModal({ type: 'none' }));
  }, [modal, plansActions.push]);

  const updateCategory = useCallback(() => {
    if (modal.type !== 'updateCategory') {
      return;
    }

    updateOne<PlanItemEntity>(
      `/specialties/${specialty.id}/academic/${revision}/${base}/items`,
      modal.id,
      { rootTitle: modal.title },
    )
      .then(response => {
        plansActions.update(
          item => item.id === response.data.id,
          response.data,
        );

        notify('success', 'Категория успешно обновлена!');
      })
      .catch(() => notify('error', 'Ошибка при обновлении категории'))
      .finally(() => setModal({ type: 'none' }));
  }, [modal, plansActions.update]);

  const dropCategory = useCallback(() => {
    if (modal.type !== 'dropCategory') {
      return;
    }

    dropOne<PlanItemEntity>(
      `/specialties/${specialty.id}/academic/${revision}/${base}/items`,
      modal.id,
    )
      .then(response => {
        plansActions.push(response.data);
        notify('success', 'Категория успешно удалена!');
        plansActions.filter(plan => plan.id !== response.data.id);
      })
      .catch(() => notify('error', 'Ошибка при удалении категории'))
      .finally(() => setModal({ type: 'none' }));
  }, [modal, plansActions.push, plansActions.filter]);

  const itemAt = useCallback(
    (rootId: number, planId: number) => {
      return plans
        .find(root => root.id === rootId)
        ?.children?.find(item => item.id === planId);
    },
    [plans],
  );

  const createItem = useCallback(
    (rootId: number, subjectId: number) => {
      createOne<PlanItemEntity>(
        `/specialties/${specialty.id}/academic/${revision}/${base}/items`,
        {
          rootId,
          subjectId,
        },
      )
        .then(response => {
          const plan = plans.find(plan => plan.id === rootId);

          if (!plan) {
            notify('error', 'Ошибка при добавлении дисциплины!');

            return;
          }

          const index = plans.indexOf(plan);

          plansActions.updateAt(index, {
            ...plan,
            children: [...plan.children, response.data],
          });

          notify('success', 'Дисциплина успешно добавлена в категорию!');
        })
        .catch(() => notify('error', 'Ошибка при добавлении дисциплины!'));
    },
    [specialty, plans, plansActions.updateAt],
  );

  const dropItem = useCallback(
    (rootId: number, planId: number) => {
      dropOne(
        `/specialties/${specialty.id}/academic/${revision}/${base}/items`,
        planId,
      )
        .then(() => {
          const plan = plans.find(item => item.id === rootId);

          if (plan) {
            const index = plans.indexOf(plan);

            plansActions.updateAt(index, {
              ...plan,
              children: plan.children.filter(child => child.id !== planId),
            });
          }

          notify('success', 'Дисциплина успешно удалена из категории!');
        })
        .catch(() => notify('error', 'Ошибка при удалении дисциплины!'));
    },
    [specialty, revision, base, plans, plansActions.updateAt],
  );

  const addTeacher = useCallback(
    (groupId: number, teacherId: number, classroomId?: number) => {
      if (modal.type !== 'subjectTeachers') {
        return;
      }

      createOne<PlanItemTeacherEntity>(
        `specialties/${specialty.id}/academic/${revision}/${base}/teachers/${modal.planId}`,
        {
          groupId,
          teacherId,
          classroomId,
        },
      )
        .then(response => {
          teachersActions.push(response.data);
          notify('success', 'Связь успешно создана');
        })
        .catch(() => {
          notify('error', 'Ошибка при создании связи!');
        });
    },
    [specialty, base, revision, modal, teachers, teachersActions],
  );

  const removeTeacher = useCallback(
    (id: number) => {
      if (modal.type !== 'subjectTeachers') {
        return;
      }

      dropOne<PlanItemTeacherEntity>(
        `specialties/${specialty.id}/academic/${revision}/${base}/teachers/${modal.planId}`,
        id,
      )
        .then(response => {
          teachersActions.filter(item => item.id !== response.data.id);

          notify('success', 'Связь успешно удалена');
        })
        .catch(() => {
          notify('error', 'Ошибка при создании связи!');
        });
    },
    [specialty, base, revision, modal, teachers, teachersActions],
  );

  const hourAt = useCallback(
    (planId: number, semesterId: number) =>
      hours.find(
        hour => hour.planId === planId && hour.semesterId === semesterId,
      ),
    [hours],
  );

  const updateHourAt = useCallback(
    (
      semesterId: number,
      rootId: number,
      planId?: number,
      hoursCount?: number,
      exam?: ExamType,
    ) => {
      putOne<void>(
        `specialties/${specialty.id}/academic/${revision}/${base}/hours/${
          planId ?? rootId
        }/${semesterId}`,
        {
          ...(planId && hoursCount !== undefined && { hours: hoursCount }),
          exam,
        },
      )
        .then(() => {
          notify('success', 'Нагрузка успешно установлена!');

          const hour = hours.find(
            hour =>
              hour.planId === (planId ?? rootId) &&
              hour.semesterId === semesterId,
          );

          if (!hour) {
            hoursActions.push({
              exam: exam ?? ExamType.NONE,
              hours: hoursCount ?? 0,
              planId: planId ?? rootId,
              semesterId: semesterId,
            });

            return;
          }

          const index = hours.indexOf(hour);

          if (exam) {
            hour.exam = exam;
          }

          if (planId && hoursCount !== undefined) {
            hour.hours = hoursCount;
          }

          hoursActions.updateAt(index, hour);
        })
        .catch(() => notify('error', 'Ошибка при установлении нагрузки!'));
    },
    [specialty, revision, base, hours, hoursActions],
  );

  const hoursCountBySemester = useCallback(
    (rootId: number, semesterId: number) => {
      const root = plans.find(root => root.id === rootId);

      if (!root) {
        return 0;
      }

      return root.children
        .map(children =>
          hours.filter(
            hour =>
              hour.planId === children.id && hour.semesterId === semesterId,
          ),
        )
        .flat()
        .map(item => item.hours)
        .reduce((a, b) => a + b, 0);
    },
    [plans, hours],
  );

  const hoursCountByPlan = useCallback(
    (rootId: number, planId?: number) => {
      if (!planId) {
        return semesters
          .map(item => hoursCountBySemester(rootId, item.id))
          .reduce((a, b) => a + b, 0);
      }

      const root = plans.find(root => root.id === rootId);

      if (!root) {
        return 0;
      }

      return hours
        .filter(hour => hour.planId === planId)
        .map(item => item.hours)
        .reduce((a, b) => a + b, 0);
    },
    [hoursCountBySemester, plans],
  );

  const revisions = useMemo(
    () =>
      Array.from(
        Array(
          base === 'FULL' ? specialty.fullYears : specialty.notFullYears,
        ).keys(),
      ).map(item => startOfEducationYear().year - item),
    [base, specialty],
  );

  const disabledSubjects = useMemo(
    () =>
      plans
        .map(plan => plan.children)
        .flat(1)
        .filter(plan => plan.subjectId !== undefined)
        .map(plan => plan.subjectId as number),
    [plans],
  );

  const value = useMemo(
    () => ({
      specialty,

      base,
      setBase,

      revisions,
      revision,
      setRevision,

      modal,
      setModal,

      meta,
      semesters,
      plans,
      teachers,
      groups,

      currentTab,
      onTabChange,

      clone,

      createCategory,
      updateCategory,
      dropCategory,

      addTeacher,
      removeTeacher,

      itemAt,
      createItem,
      dropItem,

      hourAt,
      updateHourAt,

      disabledSubjects,

      hoursCountBySemester,
      hoursCountByPlan,
    }),
    [
      specialty,
      base,
      setBase,
      revision,
      revisions,
      setRevision,
      modal,
      setModal,

      meta,
      semesters,
      plans,
      teachers,
      groups,

      currentTab,
      onTabChange,

      clone,

      createCategory,
      updateCategory,
      dropCategory,

      addTeacher,
      removeTeacher,

      itemAt,
      createItem,
      dropItem,

      hourAt,
      updateHourAt,

      disabledSubjects,

      hoursCountBySemester,
      hoursCountByPlan,
    ],
  );

  return <PlanContext.Provider value={value}>{children}</PlanContext.Provider>;
};

export const usePlan = () => useContext<PlanContextType>(PlanContext);
