import { Card } from '../../../common/block/card.component';
import { Profile } from '../../../common/component/profile/profile.component';
import { useAuth } from '../../../common/app/auth/auth.context';

export const ProfileUserDeveloper = () => {
  const { result } = useAuth();

  return (
    <Card className={'p-4 flex flex-col gap-4'}>
      <Profile
        user={result?.user && { ...result?.user, title: 'Разработчик' }}
        titled={true}
      />
    </Card>
  );
};
