import { StatementHeader } from './header/statement-header.component';
import { StatementBody } from './body/statement-body.component';
import { useSpecialtyGroupStatements } from '../../context/group-statements.context';
import { StatementBodyEmpty } from './body/statement-body-empty.component';

export const StatementRoot = () => {
  const { semesterId } = useSpecialtyGroupStatements();

  return (
    <div className={'flex flex-col gap-4'}>
      <div className={'container mx-auto flex flex-col gap-4'}>
        <StatementHeader />
        {semesterId ? <StatementBody /> : <StatementBodyEmpty />}
      </div>
    </div>
  );
};
