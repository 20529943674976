import { useParams } from 'react-router-dom';
import { PlanEditorProvider } from '../context/plan-editor.context';
import { PlanEditor } from '../components/plan-editor/plan-editor.component';

export const JournalPlanEditorModule = () => {
  const { planId } = useParams();

  return (
    <PlanEditorProvider planId={Number(planId)}>
      <PlanEditor />
    </PlanEditorProvider>
  );
};
