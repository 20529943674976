import { useAuth } from '../../../common/app/auth/auth.context';
import { Card } from '../../../common/block/card.component';
import { Profile } from '../../../common/component/profile/profile.component';

export const ProfileUserTeacher = () => {
  const { result } = useAuth();

  return (
    <Card className={'p-4 flex flex-col'}>
      <Profile
        user={result?.user && { ...result?.user, title: 'Преподаватель' }}
        titled={true}
      />

      <div className={'flex flex-col gap-2'}>
        <div className={'flex flex-row justify-between'}></div>
      </div>
    </Card>
  );
};
