import { usePlanEditor } from '../../../context/plan-editor.context';
import { Button } from '../../../../common/component/button/button.component';
import { PlanEditorContentItem } from './plan-editor-content-item.component';
import { Input } from '../../../../common/component/input/input.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { JournalCalendarPlanCategoryEntity } from '../../../entity/plan/journal-calendar-plan.item.entity';

export type PlanEditorContentCategoryProps = {
  category: JournalCalendarPlanCategoryEntity;
};

export const PlanEditorContentCategory = ({
  category,
}: PlanEditorContentCategoryProps) => {
  const { readonly, addItem, updateCategory, removeCategory } = usePlanEditor();

  return (
    <div className={'p-4 flex flex-col gap-6'}>
      <span
        className={
          'flex flex-row justify-between items-center text-left font-bold text-black dark:text-white'
        }
      >
        <Input
          background={false}
          placeholder={'Название категории'}
          defaultValue={category.title}
          className={'w-96'}
          disabled={readonly}
          onManipulated={value => updateCategory(category.id, value)}
        />
        <div className={'flex flex-row gap-2 items-center whitespace-nowrap'}>
          <span>
            {`${category.items
              .map(item => item.hours)
              .reduce((results, value) => results + value, 0)} ч.`}
          </span>

          {!readonly && (
            <div className={'flex flex-row gap-2'}>
              <Button
                color={'green'}
                small={true}
                onClick={() => addItem(category.id)}
                square={true}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
              <Button
                color={'red'}
                small={true}
                onClick={() => removeCategory(category.id)}
                square={true}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          )}
        </div>
      </span>

      <table>
        <thead className={'bg-[#F0F2F5] dark:bg-[#333333]'}>
          <tr
            className={
              'text-left font-bold text-black dark:text-white whitespace-nowrap'
            }
          >
            <th className={'rounded-l-lg py-2 pl-4'} />
            <th className={'py-2 pl-4'}>Тема</th>
            <th className={'py-2 pl-4 w-24'}>Часы</th>
            <th className={'py-2 pl-4'}>Месяц</th>
            <th className={'py-2 pl-4'}>Вид</th>
            <th className={'py-2 pl-4'}>Пособия</th>
            <th className={'py-2 pl-4'}>Задания</th>
            <th className={`${readonly && 'rounded-r-lg'} py-2 pl-4`}>
              Примечание
            </th>
            {!readonly && <th className={'rounded-r-lg py-2 pl-4'} />}
          </tr>
        </thead>

        <tbody>
          {category.items.map((value, key) => (
            <PlanEditorContentItem
              key={key}
              categoryId={category.id}
              item={value}
              index={key}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
