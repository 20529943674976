import { api } from '../common/app/networking/axios';
import { UserEntity } from '../user/user.entity';

export const findAllClassifications = (subjectId: number) =>
  api.get<UserEntity[]>(`/subject/${subjectId}/classification`);

export const addClassification = (subjectId: number, userId: number) =>
  api.post(`/subject/${subjectId}/classification/${userId}`);

export const removeClassification = (subjectId: number, userId: number) =>
  api.delete(`/subject/${subjectId}/classification/${userId}`);
