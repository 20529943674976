import { useForm } from 'react-hook-form';

import { FormValues } from './login-block.component';
import { Button } from '../../../../component/button/button.component';
import { Input } from '../../../../component/input/input.component';
import { useAuth } from '../../../../app/auth/auth.context';

export const LoginEmail = () => {
  const { login, error, clearError, isLoading } = useAuth();
  const { register, handleSubmit } = useForm<FormValues>();

  const onSubmit = handleSubmit(data => {
    login(data.mail.trim());
  });

  return (
    <form onSubmit={onSubmit} className={'w-full flex flex-col gap-6'}>
      <div className={'text-sm text-center text-[#626D7A]'}>
        Пожалуйста введите почту для входа в Платформу
      </div>

      <Input
        placeholder={'Почта'}
        {...register('mail', {
          onChange: () => clearError(),
        })}
      />

      {error && <div className={'text-sm text-[#e64646]'}>{error}</div>}

      <Button color={'blue'} disabled={isLoading}>
        Войти
      </Button>
    </form>
  );
};
