import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import {
  ProfileRelationsEntity,
  ProfileScheduleNearbyEntity,
} from './profile.entity';
import { useQueries } from '@tanstack/react-query';
import { useAuth } from '../common/app/auth/auth.context';
import { api } from '../common/app/networking/axios';
import { PermissionType } from '../common/app/auth/auth-role-permission.type';

export type ProfileContextType = {
  nearby: ProfileScheduleNearbyEntity;
  availabilities: ProfileRelationsEntity;

  isLoading: boolean;
};

export const ProfileContext = createContext<ProfileContextType>(
  {} as ProfileContextType,
);

export const ProfileProvider = ({ children }: PropsWithChildren) => {
  const { result } = useAuth();

  const [nearby, availabilities] = useQueries({
    queries: [
      {
        queryKey: ['nearby'],
        queryFn: () =>
          api
            .get<ProfileScheduleNearbyEntity>('/schedule/nearby/')
            .then(response => response.data),
      },
      {
        queryKey: ['availabilities'],
        queryFn: () =>
          api
            .get<ProfileRelationsEntity>('/journal/availabilities/')
            .then(response => response.data),
        enabled: result?.permissions?.includes(
          PermissionType.JOURNAL_MODULE_PREVIEW,
        ),
      },
    ],
  });

  const value = useMemo(
    () => ({
      nearby: nearby.data as ProfileScheduleNearbyEntity,
      availabilities: availabilities.data as ProfileRelationsEntity,

      isLoading:
        nearby.isLoading ||
        ((result?.permissions?.includes(
          PermissionType.JOURNAL_MODULE_PREVIEW,
        ) ??
          false) &&
          availabilities.isLoading),
    }),
    [nearby, availabilities],
  );

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
};

export const useProfile = () => useContext<ProfileContextType>(ProfileContext);
