import { usePlanEditor } from '../../../context/plan-editor.context';
import { Modal } from '../../../../common/component/modal/modal';

export const PlanEditorDeleteCategoryModal = () => {
  const { modal, setModal } = usePlanEditor();

  return (
    <Modal
      title={'Удалить категорию'}
      showed={modal.type === 'delete-category'}
      onClose={() => setModal({ type: 'none' })}
    >
      <div className={'flex flex-col gap-2'}></div>
    </Modal>
  );
};
