import { SpecialtyEntity } from './entity/specialty.entity';
import { UserEntity } from '../user/user.entity';
import { Profile } from '../common/component/profile/profile.component';
import { EntityModule } from '../common/entity';

export const SpecialtyModule = () => (
  <EntityModule<SpecialtyEntity>
    title={'Специальности'}
    route={'specialty'}
    columns={[
      {
        key: 'title',
        title: 'Название',
        input: {
          type: 'text',
          options: {
            required: 'Данное поле обязательно',
            minLength: {
              value: 10,
              message: 'Минимальная длина 10 символа',
            },
          },
        },
        render: item => item.title,
      },
      {
        key: 'bossId',
        title: 'Заведующий отделением',
        short: 'Заведующий',
        input: {
          type: 'service',
          route: 'users',
          options: {
            required: 'Данное поле обязательно',
          },
          titleFor: item => (item as UserEntity).pretty,
          viewFor: item => <Profile user={item as UserEntity} size={6} />,
        },
        render: item => <Profile user={item.boss} size={10} />,
      },
      {
        key: 'prefix',
        title: 'Префикс',
        input: {
          type: 'text',
          options: {
            required: 'Данное поле обязательно',
            minLength: {
              value: 2,
              message: 'Минимальная длина 2 символа',
            },
            maxLength: {
              value: 2,
              message: 'Максимальная длина 2 символа',
            },
          },
        },
        render: item => item.prefix,
      },
      {
        key: 'code',
        title: 'Код',
        input: {
          type: 'text',
          options: {
            required: 'Данное поле обязательно',
            minLength: {
              value: 8,
              message: 'Минимальная длина 8 символов',
            },
            maxLength: {
              value: 8,
              message: 'Максимальная длина 8 символов',
            },
          },
        },
        render: item => item.code,
      },
      {
        key: 'fullYears',
        title: 'Количество обучаемых лет (База 11 классов)',
        short: 'База 11 классов',
        input: {
          type: 'number',
          options: {
            required: 'Данное поле обязательно',
          },
        },
        render: item => `${item.fullYears} г.`,
      },
      {
        key: 'notFullYears',
        title: 'Количество обучаемых лет (База 9 классов)',
        short: 'База 9 классов',
        input: {
          type: 'number',
          options: {
            required: 'Данное поле обязательно',
          },
        },
        render: item => `${item.notFullYears} г.`,
      },
      {
        key: 'description',
        title: 'Описание',
        input: {
          type: 'textarea',
          options: {
            required: 'Данное поле обязательно',
            minLength: {
              value: 10,
              message: 'Минимальная длина 10 символа',
            },
          },
        },
      },
    ]}
    links={[
      {
        title: 'Учебный план',
        color: 'red',
        link: item => `${item.id}/plans`,
      },
      {
        title: 'Обучаемые группы',
        color: 'blue',
        link: item => `${item.id}/groups`,
      },
    ]}
    additionalSections={[
      {
        title: 'Информация о руководителе',
        render: item => (
          <div className="flex flex-col gap-4">
            <Profile user={item.boss} size={16} />
            <div className="text-sm text-muted-foreground">
              Заведующий отделением
            </div>
          </div>
        ),
      },
      {
        title: 'Основная информация',
        render: item => (
          <div className="space-y-4">
            <div>
              <div className="font-medium">Код специальности</div>
              <div className="text-sm text-muted-foreground">{item.code}</div>
            </div>
            <div>
              <div className="font-medium">Префикс</div>
              <div className="text-sm text-muted-foreground">{item.prefix}</div>
            </div>
            <div>
              <div className="font-medium">Срок обучения</div>
              <div className="text-sm text-muted-foreground">
                На базе 11 классов: {item.fullYears} года
                <br />
                На базе 9 классов: {item.notFullYears} года
              </div>
            </div>
          </div>
        ),
      },
      {
        title: 'Описание специальности',
        render: item => (
          <div className="prose dark:prose-invert max-w-none">
            <p>{item.description}</p>
          </div>
        ),
      },
    ]}
  />
);
