import { usePlan } from '../../../context/plan.context';
import { JournalPlanStatus } from '../../../entity/plan/journal-plan-status.entity';
import { useMap } from 'react-use';
import { useEffect } from 'react';
import { FiltersQueryItem } from './filters-query-item.component';
import { AutocompleteService } from '../../../../common/component/autocomplete-service/autocomplete-service.component';
import { SubjectEntity } from '../../../../subject/subject.entity';
import { InputSelect } from '../../../../common/component/input-select/input-select';
import { Card } from '../../../../common/block/card.component';
import { Button } from '../../../../common/component/button/button.component';
import { DateTime } from 'luxon';
import { UserEntity } from '../../../../user/user.entity';
import { Profile } from '../../../../common/component/profile/profile.component';
import { SpecialtyEntity } from '../../../../specialty/entity/specialty.entity';
import { SpecialityGroupEntity } from '../../../../specialty/entity/group.entity';
import { useAuth } from '../../../../common/app/auth/auth.context';
import { PermissionType } from '../../../../common/app/auth/auth-role-permission.type';

export type FiltersSearchTabType = {
  specialtyId: number | undefined;
  groupId: number | undefined;
  subjectId: number | undefined;
  developerId: number | undefined;
  year: number;
  status: JournalPlanStatus | undefined;
};

export const FiltersTab = () => {
  const { result } = useAuth();

  const { setQuery, page, setPage, results } = usePlan();

  const [map, { set }] = useMap<FiltersSearchTabType>({
    year: 2023,
  } as FiltersSearchTabType);

  useEffect(() => {
    setQuery(map);
    setPage(0);
  }, [map]);

  return (
    <Card className={'p-4'}>
      <div className={'flex flex-col gap-6'}>
        <div className={'flex flex-col gap-2'}>
          {result?.permissions?.includes(
            PermissionType.JOURNAL_PLAN_QUERY_EXPLICIT,
          ) && (
            <>
              <FiltersQueryItem
                title={'Специальность'}
                drop={() => set('specialtyId', undefined)}
              >
                <AutocompleteService<SpecialtyEntity>
                  title={'Специальность'}
                  route={'/specialty'}
                  selected={map.specialtyId}
                  onSelected={value => set('specialtyId', value as number)}
                  mapper={value => ({
                    value: value.id,
                    title: value.title,
                  })}
                />
              </FiltersQueryItem>

              <FiltersQueryItem
                title={'Академическая группа'}
                drop={() => set('groupId', undefined)}
              >
                <AutocompleteService<SpecialityGroupEntity>
                  title={'Академическая группа'}
                  route={'/groups'}
                  selected={map.groupId}
                  onSelected={value => set('groupId', value as number)}
                  mapper={value => ({
                    value: value.id,
                    title: value.title,
                  })}
                />
              </FiltersQueryItem>

              <FiltersQueryItem
                title={'Разработчик'}
                drop={() => set('developerId', undefined)}
              >
                <AutocompleteService<UserEntity>
                  title={'Разработчик'}
                  route={'/users'}
                  params={{
                    type: 'TEACHER',
                  }}
                  selected={map.developerId}
                  onSelected={value => set('developerId', value as number)}
                  mapper={value => ({
                    value: value.id,
                    title: value.pretty,
                    view: <Profile size={8} user={value} />,
                  })}
                />
              </FiltersQueryItem>
            </>
          )}

          <FiltersQueryItem
            title={'Дисциплина'}
            drop={() => set('subjectId', undefined)}
          >
            <AutocompleteService<SubjectEntity>
              title={'Дисциплина'}
              route={'/journal/subject'}
              selected={map.subjectId}
              onSelected={value => set('subjectId', value as number)}
              mapper={value => ({
                value: value.id,
                title: value.title,
              })}
            />
          </FiltersQueryItem>

          <FiltersQueryItem
            title={'Статус'}
            drop={() => set('status', undefined)}
          >
            <InputSelect
              title={'Статус'}
              items={[
                {
                  value: 'NOT_STARTED',
                  title: 'Не приступал',
                },
                {
                  value: 'IN_PROGRESS',
                  title: 'В разработке',
                },
                {
                  value: 'REVIEW_REQUESTED',
                  title: 'Необходимо проверить',
                },
                {
                  value: 'REVIEWED',
                  title: 'Опубликован',
                },
              ]}
              selected={map.status}
              onChange={value => set('status', value as JournalPlanStatus)}
            />
          </FiltersQueryItem>

          <FiltersQueryItem title={'Учебный год'}>
            <InputSelect
              title={'Ревизия'}
              items={Array.from(Array(5).keys()).map(value => {
                const year = DateTime.now().minus({ year: value }).year;

                return {
                  value: year,
                  title: `${year} - ${year + 1} г.`,
                };
              })}
              selected={map.year}
              onChange={value => set('year', value as number)}
            />
          </FiltersQueryItem>
        </div>

        <div className={'flex flex-col gap-4'}>
          <div className={'flex flex-row gap-4'}>
            <Button
              className={'w-1/2'}
              small={true}
              disabled={page === 0}
              onClick={() => setPage(page - 1)}
            >
              Назад
            </Button>

            <Button
              className={'w-1/2'}
              small={true}
              disabled={page === results?.meta?.pages - 1}
              onClick={() => setPage(page + 1)}
            >
              Вперед
            </Button>
          </div>

          <span className={'text-center'}>
            Страница {page + 1} из {results?.meta?.pages} (Всего:{' '}
            {results.meta?.total})
          </span>
        </div>
      </div>
    </Card>
  );
};
