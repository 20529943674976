import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BuildingEntity } from '../entity/building.entity';
import { TimeType } from '../type/time.type';
import { TimeEntity } from '../entity/time.entity';
import { useList } from 'react-use';
import { DateTime } from 'luxon';
import { useNotifier } from '../../common/app/notification/notification-context';
import { createOne, findOne, getOne } from '../../common/entity/entity.service';
import { api } from '../../common/app/networking/axios';

export type BuildingTimeContextType = {
  building: BuildingEntity | undefined;

  index: number;
  setIndex: (value: number) => void;

  type: TimeType;

  lessons: TimeEntity[];
  dates: number[];

  set: (value: number) => void;
  unset: (value: number) => void;

  add: () => void;
  remove: () => void;
  edit: (position: number, property: 'start' | 'end', value: number) => void;

  save: () => void;
};

export const BuildingTimeContext = createContext<BuildingTimeContextType>(
  {} as BuildingTimeContextType,
);

export type BuildingTimeProps = {
  buildingId?: string;
};

export const BuildingTimeProvider = ({
  children,
  buildingId,
}: PropsWithChildren & BuildingTimeProps) => {
  const { notify } = useNotifier();

  const [building, setBuilding] = useState<BuildingEntity | undefined>(
    undefined,
  );
  const [items, itemsActions] = useList<TimeEntity>([]);
  const [dates, datesActions] = useList<number>([]);
  const [index, setIndex] = useState<number>(0);

  const type = useMemo(() => Object.keys(TimeType)[index] as TimeType, [index]);

  useEffect(() => {
    findOne<BuildingEntity>('buildings', Number(buildingId))
      .then(response => setBuilding(response.data))
      .then(() => getOne<TimeEntity[]>(`buildings/${buildingId}/time`))
      .then(response => itemsActions.set(response.data))
      .then(() => getOne<number[]>(`buildings/${buildingId}/time/shorted`))
      .then(response => datesActions.set(response.data))
      .catch(() => notify('error', 'Ошибка загрузки данных'));
  }, [buildingId, itemsActions]);

  const lessons = useMemo(
    () =>
      items
        .filter(item => item.type === type)
        .sort((a, b) => a.start - b.start),
    [items, type],
  );

  const add = useCallback(
    () =>
      itemsActions.push({
        type,
        start: DateTime.now().startOf('day').toUnixInteger(),
        end: DateTime.now().startOf('day').toUnixInteger(),
      }),
    [type, itemsActions],
  );

  const remove = useCallback(
    () => itemsActions.removeAt(items.length - 1),
    [items, itemsActions],
  );

  const edit = useCallback(
    (position: number, property: 'start' | 'end', value: number) => {
      const item = items
        .filter(item => item.type === type)
        // .sort((a, b) => a.start - b.start)
        .find((_, index) => index === position);

      if (!item) {
        console.log('item not found');

        return;
      }

      const indexOf = items.indexOf(item);

      console.log('edited:', position, 'property:', property, 'value:', value);

      itemsActions.updateAt(indexOf, {
        ...item,

        [property]: value,
      });
    },
    [items, type, itemsActions],
  );

  const save = useCallback(() => {
    if (items.some(item => !item.start || !item.end)) {
      notify('error', 'Не все поля заполнены!');
      return;
    }

    createOne(`buildings/${buildingId}/time`, items)
      .then(() => notify('success', 'Расписание звонков успешно сохранено!'))
      .catch(() =>
        notify('error', 'Ошибка при сохранении расписания звонков!'),
      );
  }, [buildingId, items]);

  const set = useCallback(
    (value: number) =>
      api
        .post(`/buildings/${buildingId}/time/shorted/${value}`)
        .then(() => {
          datesActions.push(value);
          notify('success', 'Сокращенный день успешно добавлен!');
        })
        .catch(() =>
          notify('error', 'Ошибка при добавлении сокращенного дня!'),
        ),
    [buildingId, datesActions],
  );

  const unset = useCallback(
    (value: number) =>
      api
        .delete(`/buildings/${buildingId}/time/shorted/${value}`)
        .then(() => {
          const index = dates.indexOf(value);
          datesActions.removeAt(index);

          notify('success', 'Сокращенный день успешно удален!');
        })
        .catch(() => notify('error', 'Ошибка при удалении сокращенного дня!')),
    [buildingId, dates, datesActions],
  );

  const value = useMemo(
    () => ({
      building,

      index,
      setIndex,

      type,

      lessons,
      dates,
      add,
      remove,
      edit,

      set,
      unset,
      save,
    }),
    [
      building,
      index,
      setIndex,
      type,
      lessons,
      dates,
      add,
      remove,
      edit,
      set,
      unset,
      save,
    ],
  );

  return (
    <BuildingTimeContext.Provider value={value}>
      {children}
    </BuildingTimeContext.Provider>
  );
};

export const useBuildingTime = () =>
  useContext<BuildingTimeContextType>(BuildingTimeContext);
