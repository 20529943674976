import { useUsers } from '../user.context';
import { Modal } from '../../common/component/modal/modal';
import { AutocompleteService } from '../../common/component/autocomplete-service/autocomplete-service.component';
import { RoleEntity } from '../../role/role.entity';
import { Badge } from '../../common/component/badge/badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export const UserRolesModal = () => {
  const { user, setUser, roles, addRole, removeRole } = useUsers();

  return (
    <Modal
      title={'Классификация'}
      showed={user !== undefined}
      onClose={() => setUser(undefined)}
    >
      <div className={'flex flex-col gap-4'}>
        <AutocompleteService<RoleEntity>
          title={'Роль'}
          route={'roles'}
          withoutSetter={true}
          disabled={roles
            .sort((a, b) => a.priority - b.priority)
            .map(item => item.id)}
          onSelected={selected => addRole(Number(selected))}
          mapper={role => ({
            value: role.id,
            view: (
              <div className={'flex flex-row justify-between'}>
                <span>{role.title}</span>
                <Badge color={'red'}>Приоритет: {role.priority}</Badge>
              </div>
            ),
          })}
        />

        <div className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
          Список ролей
        </div>

        <div
          className={
            'flex flex-col gap-2 divide-y divide-[#F0F2F5] dark:divide-[#333333]'
          }
        >
          {roles.length > 0 &&
            roles.map((role, index) => (
              <div key={index} className={'flex flex-row pt-1 justify-between'}>
                <span>{role.title}</span>

                <div className={'flex flex-row gap-2'}>
                  <Badge color={'red'}>Приоритет: {role.priority}</Badge>
                  <button onClick={() => removeRole(role.id)}>
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
            ))}

          {roles.length === 0 && <div>Роли отсутствуют</div>}
        </div>
      </div>
    </Modal>
  );
};
