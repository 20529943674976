import { SubjectEntity } from '../subject.entity';
import { useSubject } from '../subject.context';
import { Fragment } from 'react';
import { EntityModule } from '../../common/entity';

export const SubjectTable = () => {
  const { setSubject } = useSubject();

  return (
    <Fragment>
      <EntityModule<SubjectEntity>
        title={'Дисциплины'}
        route={'subject'}
        columns={[
          {
            key: 'title',
            title: 'Название',
            input: {
              type: 'text',
              options: {
                required: 'Данное поле обязательно',
              },
            },
            render: item => item.title,
          },
          {
            key: 'short',
            title: 'Сокращенное название',
            input: {
              type: 'text',
              options: {
                required: 'Данное поле обязательно',
              },
            },
            render: item => item.title,
          },
        ]}
        links={[
          {
            title: 'Классификации',
            color: 'yellow',
            onClick: item => setSubject(item),
          },
        ]}
      />
    </Fragment>
  );
};
