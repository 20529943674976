import { Card } from '../../../common/block/card.component';
import { useSpecialtyGroupRelation } from '../../context/group-relation.context';

export const HeaderComponent = () => {
  const { group } = useSpecialtyGroupRelation();

  return (
    <Card className={'flex flex-row justify-between place-items-center p-4'}>
      <div className={'flex flex-col gap-1'}>
        <div className={'text-lg font-bold'}>{group?.title}</div>
        <div className={'text-md font-medium'}>Связи академической группы</div>
      </div>
    </Card>
  );
};
