import { Card } from '../../../../common/block/card.component';
import { FiltersQueryItem } from '../../plan/filters/filters-query-item.component';
import { useJournalItems } from '../../../context/items.context';
import React, { Fragment, useMemo } from 'react';
import { Toggle } from '../../../../common/component/toggle/toggle.component';
import { InputSelect } from '../../../../common/component/input-select/input-select';
import { Badge2 } from '../../../../common/component/badge2/badge';
import { PlanEditorMetaItem } from '../../plan-editor/meta/plan-editor-meta-item.component';
import { Profile } from '../../../../common/component/profile/profile.component';
import { UserEntity } from '../../../../user/user.entity';
import { convertToRoman } from '../../../../common/utils/number.utils';

export const JournalMeta = () => {
  const {
    meta,
    subjectId,
    setSubjectId,
    groupId,
    setGroupId,
    totals,
    setTotals,
    availabilities,
  } = useJournalItems();

  const values = useMemo(
    () =>
      [
        ...new Map(
          availabilities?.subjects
            ?.map(relation => relation.group)
            ?.map(item => [item.id, item]),
        ).values(),
      ]
        .map(group => ({
          ...group,
          isBoss: availabilities?.boss?.some(item => item.id === group.id),
        }))
        .sort(
          (first, second) =>
            Number(second.isBoss) - Number(first.isBoss) ||
            first.title.localeCompare(second.title),
        ),
    [availabilities],
  );

  return (
    <div className={'flex flex-col gap-4'}>
      <Card className={'p-4 flex flex-col gap-4'}>
        <span className={'text-lg font-semibold'}>Настройки</span>

        <FiltersQueryItem title={'Академическая группа'}>
          <InputSelect
            search={true}
            title={'Академическая группа'}
            items={values.map(group => ({
              value: group.id,
              title: group.title,
              view: (
                <div className={'flex flex-row justify-between'}>
                  <span>{group.title}</span>

                  {availabilities?.boss?.some(item => item.id === group.id) && (
                    <Badge2 color={'yellow'}>Классное руководство</Badge2>
                  )}
                </div>
              ),
            }))}
            selected={groupId}
            onChange={value => setGroupId(Number(value))}
          />
        </FiltersQueryItem>

        {!!groupId && (
          <Fragment>
            <FiltersQueryItem title={'Дисциплина'}>
              <InputSelect
                search={true}
                title={'Дисциплина'}
                items={
                  availabilities?.subjects
                    ?.filter(relation => relation.group.id === groupId)
                    ?.sort(
                      (first, second) =>
                        Number(second.isTeacher) - Number(first.isTeacher),
                    )
                    .map(item => ({
                      value: item.subject.id,
                      title: item.subject.title,
                      view: (
                        <div className={'flex flex-row justify-between'}>
                          <span>{item.subject.title}</span>

                          {item.isTeacher && (
                            <Badge2 color={'green'}>Нагрузка</Badge2>
                          )}
                        </div>
                      ),
                    })) || []
                }
                selected={subjectId}
                onChange={value => setSubjectId(Number(value))}
              />
            </FiltersQueryItem>

            <Toggle
              label={'Только итоговые'}
              checked={totals}
              onChange={setTotals}
            />
          </Fragment>
        )}
      </Card>

      <Card className={'p-4 flex flex-col gap-4'}>
        <span className={'text-lg font-semibold'}>Мета-информация</span>

        <div className={'flex flex-col gap-2'}>
          {meta?.boss && (
            <PlanEditorMetaItem title={'Классный руководитель'}>
              <Profile user={meta?.boss as unknown as UserEntity} size={8} />
            </PlanEditorMetaItem>
          )}

          {meta?.teachers &&
            meta.teachers.map(teacher => (
              <PlanEditorMetaItem key={teacher.id} title={'Преподаватель'}>
                <Profile user={teacher as unknown as UserEntity} size={8} />
              </PlanEditorMetaItem>
            ))}

          <PlanEditorMetaItem title={'Количество студентов'}>
            <span>{meta?.students?.length || 0}</span>
          </PlanEditorMetaItem>

          <PlanEditorMetaItem title={'Нагрузка'}>
            {meta?.semesters
              .sort((first, second) => (first.start || 0) - (second.start || 0))
              .map(
                (semester, index) =>
                  `${semester.hours} ч. (${convertToRoman(index + 1)} сем.)`,
              )
              .join(' / ')}
          </PlanEditorMetaItem>
        </div>
      </Card>
    </div>
  );
};
