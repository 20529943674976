import { api } from '../../app/networking/axios';

export const findByQuery = <ItemType>(
  route: string,
  search: string,
  params?: Record<string, string>,
) =>
  api.get<ItemType[]>(route, {
    params: {
      offset: 0,
      limit: 10,
      ...params,
      ...(search && search.length >= 1 ? { search } : {}),
    },
  });

export const findById = <ItemType>(route: string, id: number | string) =>
  api.get<ItemType>(`${route}/${id}`);
