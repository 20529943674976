import { JournalTableStudentProfile } from './journal-table-student-profile.component';
import { JournalTableStudentRow } from './journal-table-student-row.component';
import { UserEntity } from '../../../../user/user.entity';
import { useJournalItems } from '../../../context/items.context';

export const JournalTable = () => {
  const { meta } = useJournalItems();

  return (
    <div className={'flex flex-col'}>
      {meta?.students?.map((student, index, array) => (
        <div
          key={index}
          className={
            'flex flex-row hover:bg-neutral-100 hover:dark:bg-neutral-700'
          }
        >
          <JournalTableStudentProfile index={index} student={student} />
          <JournalTableStudentRow
            student={student as unknown as UserEntity}
            lastItems={index >= array.length - 3}
          />
        </div>
      ))}
    </div>
  );
};
