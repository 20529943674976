import { useForm } from 'react-hook-form';
import {
  KeyboardEventHandler,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { DateTime, Duration } from 'luxon';
import { Button } from '../../../../component/button/button.component';
import { Input } from '../../../../component/input/input.component';
import { useAuth } from '../../../../app/auth/auth.context';

export const LoginCode = () => {
  const { auth, login, verify, error, clearError, isLoading } = useAuth();
  const { register, setFocus, setValue, getValues } = useForm();

  const [time, setTime] = useState<Duration | undefined>(
    DateTime.fromSeconds(auth.lastSent ?? 0)
      .plus({ second: 60 })
      .diff(DateTime.now(), 'second'),
  );

  useEffect(() => {
    setTime(
      DateTime.fromSeconds(auth.lastSent ?? 0)
        .plus({ second: 60 })
        .diff(DateTime.now(), 'second'),
    );

    const interval = setInterval(() => {
      setTime(
        DateTime.fromSeconds(auth.lastSent ?? 0)
          .plus({ second: 60 })
          .diff(DateTime.now(), 'second'),
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [auth]);

  useEffect(() => {
    if (error !== undefined) {
      Array.from(Array(6).keys()).map(key => setValue(`pin-${key}`, undefined));

      setFocus('pin-0');
    }
  }, [error, setFocus, setValue]);

  const handleKeyPress = useCallback(
    (index: number): KeyboardEventHandler<HTMLInputElement> => {
      return (event: KeyboardEvent<HTMLInputElement>) => {
        const value = getValues()[`pin-${index}`];

        if (event.key === 'Backspace' && value.length === 0) {
          clearError();
          event.preventDefault();
          setFocus(`pin-${index - 1}`);
        } else if (
          event.key === 'Decimal' ||
          !isNaN(Number(event.key)) ||
          event.code.startsWith('Digit')
        ) {
          clearError();
          setValue(`pin-${index}`, event.key);
          setFocus(`pin-${index + 1}`);
          event.preventDefault();

          if (index === 5) {
            const values = getValues();

            verify(
              Number(
                Object.keys(values)
                  .map(key => values[key])
                  .join(''),
              ),
            );

            return;
          }
        } else if (event.key === 'ArrowRight') {
          setFocus(`pin-${index + 1}`);
        } else if (event.key === 'ArrowLeft') {
          setFocus(`pin-${index - 1}`);
        } else if (event.key !== 'Backspace') {
          event.preventDefault();
        }
      };
    },
    [getValues, clearError, setFocus, setValue, verify],
  );

  return (
    <div className={'flex flex-col gap-4 text-center'}>
      <div className={'text-sm text-[#626D7A]'}>
        <div>Мы только что отправили Вам код авторизации на почту</div>
        <span className={'text-[#3375f6]'}>{auth.mail ?? ''}</span>
      </div>
      <div className={'flex flex-row gap-2'}>
        {Array.from(Array(6).keys()).map(key => (
          <Input
            key={key}
            disabled={isLoading}
            className={'aspect-square text-center py-1 px-1 select-none'}
            maxLength={1}
            onKeyDown={handleKeyPress(key)}
            {...register(`pin-${key}`)}
          />
        ))}
      </div>

      {time &&
        ((time?.get('seconds') ?? 0) >= 1 ? (
          <div>Код придет в течении {time?.toFormat('mm:ss')}</div>
        ) : (
          <Button color={'blue'} onClick={() => login(auth.mail ?? '')}>
            Отправить еще раз
          </Button>
        ))}

      {error && <div className={'text-sm text-[#e64646]'}>{error}</div>}
    </div>
  );
};
