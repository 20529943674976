import { NavLink } from 'react-router-dom';
import {
  faCalendar,
  faFile,
  faLayerGroup,
  faRectangleAd,
  faUserGroup,
  faUserLock,
} from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../auth/auth.context';
import { PermissionType } from '../../auth/auth-role-permission.type';
import { DropdownMenu } from '../../../component/dropdown-menu/dropdown-menu.component';

export const Navigation = () => {
  const { result } = useAuth();

  return (
    <div className={'grow flex justify-center gap-4 items-center'}>
      {result?.permissions?.includes(PermissionType.BUILDING_CREATE) && (
        <NavLink to={'/buildings'}>Корпуса</NavLink>
      )}

      {(result?.permissions?.includes(PermissionType.ROLE_CREATE) ||
        result?.permissions?.includes(PermissionType.USER_CREATE)) && (
        <DropdownMenu
          title={'Структуры'}
          items={[
            ...(result?.permissions?.includes(PermissionType.USER_CREATE) ??
            false
              ? [
                  {
                    title: 'Пользователи',
                    link: '/users',
                    description:
                      'Интерфейс для управления список сотрудников, преподавателей и студентов, которые зарегистрированы в платформе организации',
                    icon: faUserGroup,
                  },
                ]
              : []),
            ...(result?.user?.isAdmin ||
            result?.permissions?.includes(PermissionType.ROLE_CREATE)
              ? [
                  {
                    title: 'Полномочия',
                    link: '/roles',
                    description:
                      'Интерфейс для управления множеством полномочий определенных групп пользователей',
                    icon: faUserLock,
                  },
                ]
              : []),
          ]}
        />
      )}

      {result?.user?.isAdmin && (
        <DropdownMenu
          title={'Журналы'}
          items={[
            {
              title: 'Календарно-тематическое планирование',
              link: '/journals/plans/',
              description: 'DESCRIPTION IN PROGRESS',
              icon: faUserGroup,
            },
            {
              title: 'Электронные журналы',
              link: '/journals/items/subject/',
              description: 'DESCRIPTION IN PROGRESS',
              icon: faUserGroup,
            },
          ]}
        />
      )}

      {result?.permissions?.includes(PermissionType.SPECIALTY_CREATE) && (
        <DropdownMenu
          title={'Учебная работа'}
          items={[
            {
              title: 'Специальности',
              link: '/specialties',
              description:
                'Интерфейс для управления специальностями, которые присутствуют в образовательной платформе организации.',
              icon: faLayerGroup,
            },
            {
              title: 'Дисциплины',
              link: '/subjects',
              description:
                'Интерфейс для управления дисциплинами, которые присутствуют в образовательной платформе организации.',
              icon: faRectangleAd,
            },

            {
              title: 'Задачи',
              link: '/tasks',
              description: 'Интерфейс для создания отчетов и ведомостей',
              icon: faFile,
            },

            {
              title: 'Расписание',
              link: '/schedule',
              description:
                'Интерфейс для управления семестровым и текущем расписанием.',
              icon: faCalendar,
            },
          ]}
        />
      )}
    </div>
  );
};
