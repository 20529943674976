import { Control, Controller } from 'react-hook-form';

import { EntityColumnType, EntityToggleColumnType } from '../../../entity.type';
import { Toggle } from '../../../../component/toggle/toggle.component';

export type EntityToggleFieldProps<ItemType> = {
  column: EntityColumnType<ItemType>;
  input: EntityToggleColumnType;
  control: Control;
  error?: string;
};

export const EntityToggleField = <ItemType,>({
  column,
  input,
  control,
}: EntityToggleFieldProps<ItemType>) => (
  <Controller
    name={column.key}
    control={control}
    rules={input.options}
    render={field => (
      <Toggle
        label={input.title}
        checked={Boolean(field.field.value)}
        onChange={field.field.onChange}
        disabled={input.disabled ?? false}
      />
    )}
  />
);
