import { usePlanEditor } from '../../../context/plan-editor.context';
import { Modal } from '../../../../common/component/modal/modal';
import { Button } from '../../../../common/component/button/button.component';
import { FileUploader } from '../../../../common/component/file/file-uploader';
import { useState } from 'react';

export const PlanEditorImportModalComponent = () => {
  const { modal, setModal, doImport } = usePlanEditor();
  const [files, setFiles] = useState<File[]>([]);

  return (
    <Modal
      title={'Импортировать календарно-тематический план'}
      showed={modal.type === 'import'}
      onClose={() => setModal({ type: 'none' })}
    >
      <div className={'flex flex-col gap-4'}>
        <FileUploader
          maxFiles={1}
          maxSize={0}
          formats={['.xlsx']}
          onFiles={setFiles}
          showSelected={false}
        />

        <Button
          color={'green'}
          disabled={files.length === 0}
          onClick={() => doImport(files[0])}
        >
          Загрузить
        </Button>
      </div>
    </Modal>
  );
};
