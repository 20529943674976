import { usePlanEditor } from '../../../context/plan-editor.context';
import { Modal } from '../../../../common/component/modal/modal';
import { Button } from '../../../../common/component/button/button.component';

export const PlanEditorDeleteModalComponent = () => {
  const { modal, setModal, drop } = usePlanEditor();

  return (
    <Modal
      title={'Удалить календарно-тематический план'}
      showed={modal.type === 'delete'}
      onClose={() => setModal({ type: 'none' })}
    >
      <div className={'flex flex-col gap-4'}>
        <h1>Вы действительно хотите удалить календарно-тематический план?</h1>

        <div className={'flex flex-row gap-4'}>
          <Button color={'red'} onClick={drop}>
            Да, удалить
          </Button>
          <Button onClick={() => setModal({ type: 'none' })}>
            Нет, отменить
          </Button>
        </div>
      </div>
    </Modal>
  );
};
