import {
  Badge,
  BadgeColorVariant,
} from '../../../../../common/component/badge/badge';

const colorValues: Record<number, BadgeColorVariant> = {
  0: 'red',
  2: 'red',
  3: 'purple',
  4: 'yellow',
  5: 'green',
};

export type StatementBodySubjectItemMarkProps = {
  mark: number | undefined;
};

export const StatementBodySubjectItemMark = ({
  mark,
}: StatementBodySubjectItemMarkProps) => {
  if (mark === undefined) {
    return <span />;
  }

  return (
    <Badge color={colorValues[mark]}>
      <span className={'font-bold'}>{mark === 0 ? 'Н/А' : mark}</span>
    </Badge>
  );
};
