import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SpecialtyEntity } from '../entity/specialty.entity';
import { useNotifier } from '../../common/app/notification/notification-context';
import { findOne } from '../../common/entity/entity.service';

export type SpecialtyGroupContextType = {
  specialty: SpecialtyEntity | undefined;
};

export const SpecialtyGroupContext = createContext<SpecialtyGroupContextType>(
  {} as SpecialtyGroupContextType,
);

export type SpecialtyGroupProps = {
  specialtyId?: string;
};

export const SpecialtyGroupProvider = ({
  children,
  specialtyId,
}: PropsWithChildren & SpecialtyGroupProps) => {
  const { notify } = useNotifier();

  const [specialty, setSpecialty] = useState<SpecialtyEntity | undefined>(
    undefined,
  );

  useEffect(() => {
    findOne<SpecialtyEntity>('specialty', Number(specialtyId))
      .then(response => setSpecialty(response.data))
      .catch(() => notify('error', 'Ошибка загрузки данных'));
  }, [specialtyId]);

  const value = useMemo(
    () => ({
      specialty,
    }),
    [specialty],
  );

  return (
    <SpecialtyGroupContext.Provider value={value}>
      {children}
    </SpecialtyGroupContext.Provider>
  );
};

export const useSpecialtyGroup = () =>
  useContext<SpecialtyGroupContextType>(SpecialtyGroupContext);
