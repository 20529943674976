import { Tab } from '@headlessui/react';
import { PlanHeader } from './plan-header.component';
import { PlanTable } from './table/plan-table.component';
import { PlanSemester } from './semester/plan-semester.component';
import { usePlan } from '../../context/plan.context';
import { PlanHeaderCloneModal } from './table/plan-header-clone-modal.component';

export const PlanTabs = () => {
  const { onTabChange, currentTab } = usePlan();

  return (
    <div className={'container mx-auto flex flex-col gap-4'}>
      <Tab.Group
        selectedIndex={currentTab}
        onChange={index => onTabChange(index)}
      >
        <PlanHeader />
        <PlanHeaderCloneModal />

        <Tab.Panel key={0}>
          <PlanTable />
        </Tab.Panel>
        <Tab.Panel key={1}>
          <PlanSemester />
        </Tab.Panel>
      </Tab.Group>
    </div>
  );
};
