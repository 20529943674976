import { useParams } from 'react-router-dom';
import { SpecialtyGroupProvider } from '../context/group.context';
import { SpecialtyGroupParams } from './plan.index';
import { SpecialtyGroupRelationProvider } from '../context/group-relation.context';
import { HeaderComponent } from '../components/group-relation/header.component';
import { RelationTable } from '../components/group-relation/relation-table.component';
import { GroupRelationType } from '../entity/group-relation.entity';

export type SpecialtyGroupRelationParams = {
  groupId: string;
};

export const SpecialtyGroupRelationModule = () => {
  const { specialtyId, groupId } = useParams<
    SpecialtyGroupParams & SpecialtyGroupRelationParams
  >();

  return (
    <SpecialtyGroupProvider specialtyId={specialtyId}>
      <SpecialtyGroupRelationProvider groupId={groupId}>
        <div className={'container mx-auto flex flex-col gap-4'}>
          <HeaderComponent />
          <RelationTable title={'Студенты'} type={GroupRelationType.STUDENT} />
          <RelationTable title={'Старосты'} type={GroupRelationType.CAPTAIN} />
          <RelationTable title={'Кураторы'} type={GroupRelationType.CURATOR} />
        </div>
      </SpecialtyGroupRelationProvider>
    </SpecialtyGroupProvider>
  );
};
