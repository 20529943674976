import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AuthRequiredComponent } from '../auth/auth-required.component';
import { HomeLayout } from './layout/home.layout';
import { PolicyModule } from '../../global/policy';
import { SupportModule } from '../../../support';
import { AuthModule } from '../../global/auth';
import { HomeModule } from '../../../home';
import { BuildingModule } from '../../../building';
import { SubjectModule } from '../../../subject';
import { SpecialtyModule } from '../../../specialty';
import { InstitutionModule } from '../../../institution';
import { SpecialtyGroupModule } from '../../../specialty/submodule/group.index';
import { SpecialtyGroupRelationModule } from '../../../specialty/submodule/group-relation.index';
import { SpecialtyGroupStatementsModule } from '../../../specialty/submodule/group-statements.index';
import { JournalPlanModule } from '../../../journal/module/plan.index';
import { JournalPlanEditorModule } from '../../../journal/module/plan-editor.index';
import { JournalItemsModule } from '../../../journal/module/items.index';
import { SpecialtyPlanModule } from '../../../specialty/submodule/plan.index';
import { ClassroomModule } from '../../../building/submodule/classroom.index';
import { BuildingTimeModule } from '../../../building/submodule/time.index';
import { RoleModule } from '../../../role';
import { UserModule } from '../../../user';
import { ScheduleModule } from '../../../schedule';
import { DevelopersModal } from '../../global/auth/components/terms/developers-modal.component';
import { SimulationModal } from '../../global/auth/components/simulation-modal.component';

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/policy'} element={<PolicyModule />} />
        <Route path={'/support'} element={<SupportModule />} />

        {/* public routes */}
        <Route element={<AuthRequiredComponent inverse={true} />}>
          <Route
            index
            path={'/'}
            element={<AuthModule isAllowedLoginBlock={false} />}
          />

          <Route
            index
            path={'/auth'}
            element={<AuthModule isAllowedLoginBlock={true} />}
          />
        </Route>

        {/* private routes */}
        <Route element={<AuthRequiredComponent />}>
          <Route element={<HomeLayout />}>
            <Route path={'/home'} element={<HomeModule />} />
            <Route path={'/buildings'} element={<BuildingModule />} />
            <Route path={'/subjects'} element={<SubjectModule />} />
            <Route path={'/specialties'} element={<SpecialtyModule />} />
            <Route path={'/institutions'} element={<InstitutionModule />} />
            <Route
              path={'/specialties/:specialtyId/groups'}
              element={<SpecialtyGroupModule />}
            />
            <Route
              path={'/specialties/:specialtyId/groups/:groupId/relations'}
              element={<SpecialtyGroupRelationModule />}
            />
            <Route
              path={'/specialties/:specialtyId/groups/:groupId/statements'}
              element={<SpecialtyGroupStatementsModule />}
            />
            <Route path={'/journals/plans/'} element={<JournalPlanModule />} />
            <Route
              path={'/journals/plans/:planId/editor/'}
              element={<JournalPlanEditorModule />}
            />
            <Route
              path={'/journals/items/subject/'}
              element={<JournalItemsModule />}
            />
            <Route
              path={'/specialties/:specialtyId/plans'}
              element={<SpecialtyPlanModule />}
            />
            <Route
              path={'/buildings/:buildingId/classrooms'}
              element={<ClassroomModule />}
            />
            <Route
              path={'/buildings/:buildingId/time'}
              element={<BuildingTimeModule />}
            />
            <Route path={'/roles'} element={<RoleModule />} />
            <Route path={'/users'} element={<UserModule />} />
            <Route path={'/schedule'} element={<ScheduleModule />} />
          </Route>
        </Route>
      </Routes>

      <DevelopersModal />
      <SimulationModal />
    </BrowserRouter>
  );
};
