import { Button } from '../../../component/button/button.component';
import { Modal } from '../../../component/modal/modal';
import { useEntity } from '../../entity.context';

export const EntityDropModal = <EntityType,>() => {
  const { drop, modal, setModal } = useEntity<EntityType>();

  return (
    <Modal
      title={'Удаление'}
      showed={modal.type === 'drop'}
      onClose={() =>
        setModal({
          type: 'none',
        })
      }
    >
      <div className={'flex flex-col gap-4'}>
        <h1>Вы действительно хотите удалить ????</h1>

        <div className={'flex flex-row gap-4'}>
          <Button color={'red'} onClick={drop}>
            Да, удалить
          </Button>
          <Button onClick={() => setModal({ type: 'none' })}>
            Нет, отменить
          </Button>
        </div>
      </div>
    </Modal>
  );
};
