import { Control, Controller } from 'react-hook-form';
import { registerLocale } from 'react-datepicker';

import { EntityColumnType, EntitySelectColumnType } from '../../../entity.type';

import ru from 'date-fns/locale/ru';
import { InputSelect } from '../../../../component/input-select/input-select';

registerLocale('ru', ru);

export type EntitySelectFieldProps<ItemType> = {
  column: EntityColumnType<ItemType>;
  input: EntitySelectColumnType;
  control: Control;
  error?: string;
};

export const EntitySelectField = <ItemType,>({
  column,
  input,
  control,
  error,
}: EntitySelectFieldProps<ItemType>) => (
  <Controller
    name={column.key}
    control={control}
    rules={input.options}
    render={field => (
      <InputSelect
        title={column.title}
        selected={field.field.value}
        items={input.items}
        onChange={field.field.onChange}
        error={error}
      />
    )}
  />
);
