import { useAuth } from '../../../../app/auth/auth.context';
import { Modal } from '../../../../component/modal/modal';
import { Profile } from '../../../../component/profile/profile.component';

export const DevelopersModal = () => {
  const { modal, setModal, developers } = useAuth();

  return (
    <Modal
      showed={modal.type === 'developers'}
      onClose={() =>
        setModal({
          type: 'none',
        })
      }
      title={'Разработчики'}
    >
      <div className={'flex flex-col gap-2'}>
        {developers.map(developer => (
          <Profile key={developer.id} user={developer} titled={true} />
        ))}
      </div>
    </Modal>
  );
};
