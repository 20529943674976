import { usePlan } from '../../context/plan.context';
import { ItemsTab } from './items/items-tab.component';
import { FiltersTab } from './filters/filters-tab.component';
import { CardLoader } from '../../../common/component/loading/card-loader.component';

export const PlanContent = () => {
  const { isLoading } = usePlan();

  return (
    <div className={'flex flex-row gap-4 w-full'}>
      <div className={'flex w-3/4'}>
        {isLoading ? <CardLoader /> : <ItemsTab />}
      </div>

      <div className={'w-1/4'}>
        <FiltersTab />
      </div>
    </div>
  );
};
