import {
  NewsEntity,
  NewsEntityValues,
  ReactionTypeEntity,
} from './news.entity';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  createItem,
  createPoll,
  deleteItem,
  findAll,
  findReactions,
  setReactions,
  updateItem,
  voteItem,
} from './news.service';
import { useEffectOnce, useList } from 'react-use';
import { useNotifier } from '../common/app/notification/notification-context';

export type NewsContextModalNoneType = {
  type: 'none';
};

export type NewsContextModalCreateType = {
  type: 'create';
};

export type NewsContextModalUpdateType = {
  type: 'update';
  item: NewsEntity;
};

export type NewsContextModalDeleteType = {
  type: 'delete';
  item: NewsEntity;
};

export type NewsContextPollType = {
  title: string;
  items: string[];
};

export type NewsContextModalType =
  | NewsContextModalNoneType
  | NewsContextModalCreateType
  | NewsContextModalUpdateType
  | NewsContextModalDeleteType;

export type NewsContextType = {
  items: NewsEntity[];
  reactions: ReactionTypeEntity[];
  isLoading: boolean;
  poll: boolean;

  react: (newsId: number, type: string) => void;
  vote: (pollId: number, answers: number[]) => void;

  setPollStatus: (status: boolean) => void;
  modify: (values: NewsEntityValues) => void;
  drop: () => void;

  modal: NewsContextModalType;
  setModal: (value: NewsContextModalType) => void;
};

export const NewsContext = createContext<NewsContextType>(
  {} as NewsContextType,
);

export const NewsProvider = ({ children }: PropsWithChildren) => {
  const { notify } = useNotifier();
  const [modal, setModal] = useState<NewsContextModalType>({
    type: 'none',
  });
  const [poll, setPollStatus] = useState<boolean>(false);

  const { data } = useQuery({
    queryKey: ['reactions'],
    queryFn: findReactions,
  });

  const [isLoading, setLoading] = useState<boolean>(false);
  // const [page, setPage] = useState<number>(0);
  const [items, { set, updateAt, insertAt, filter }] = useList<NewsEntity>([]);

  useEffectOnce(() => {
    setLoading(true);

    findAll(0, 10)
      .then(items => set(items.data))
      .finally(() => setLoading(false));
  });

  useEffect(() => {
    if (modal.type !== 'none') {
      setPollStatus(false);
    }
  }, [modal]);

  const react = useCallback(
    (newsId: number, type: string) => {
      setReactions(newsId, type).then(response => {
        const item = items.find(item => item.id === newsId);

        if (item) {
          const index = items.indexOf(item);

          updateAt(index, {
            ...item,
            reactions: response.data,
          });
        }
      });
    },
    [items, updateAt],
  );

  const vote = useCallback(
    (pollId: number, answers: number[]) => {
      voteItem(pollId, answers)
        .then(response => {
          const item = items.find(item => item.poll?.id === response.data.id);

          if (item) {
            const index = items.indexOf(item);

            updateAt(index, {
              ...item,
              poll: response.data,
            });
          }
        })
        .catch(console.log);
    },
    [items, updateAt],
  );

  const modify = useCallback(
    async (values: NewsEntityValues) => {
      if (poll && values.poll.title.length < 5) {
        notify(
          'error',
          'Название опроса должно состоять минимум из 5 символов',
        );

        return;
      }

      if (
        poll &&
        values.poll.items.filter(item => item.length > 0).length < 2
      ) {
        notify('error', 'Опрос должен содержать минимум два варианта ответа');

        return;
      }

      const pollId = poll
        ? await createPoll({
            ...values.poll,
            items: values.poll.items.map(item => ({ title: item })),
          }).then(item => item.data.id)
        : -1;

      (modal.type === 'update'
        ? updateItem(modal.item.id, values)
        : createItem({
            title: values.title,
            short: values.short,
            content: values.content,
            attachments: values.attachments,
            ...(values.isAdmin && { isAdmin: values.isAdmin }),
            ...(values.notification && { notification: values.notification }),

            ...(poll ? { pollId } : {}),
          })
      )
        .then(item => {
          if (modal.type === 'create') {
            insertAt(0, item.data);
          }

          setModal({ type: 'none' });
          notify(
            'success',
            `Новость успешно ${
              modal.type === 'create' ? 'опубликована' : 'обновлена'
            }!`,
          );
        })
        .catch(() =>
          notify(
            'error',
            `Ошибка при ${
              modal.type === 'create' ? 'публикации' : 'обновлении'
            } новости!`,
          ),
        );
    },
    [poll, modal],
  );

  const drop = useCallback(() => {
    if (modal.type !== 'delete') {
      return;
    }

    deleteItem(modal.item.id)
      .then(() => {
        filter(item => item.id !== modal.item.id);
        notify('success', 'Новость успешно удалена!');
        setModal({ type: 'none' });
      })
      .catch(() => notify('error', 'Ошибка при удалении новости!'));
  }, [modal, filter]);

  const value = useMemo(
    () => ({
      items,
      reactions: data?.data ?? [],
      isLoading,

      react,
      vote,
      modal,
      setModal,

      poll,
      setPollStatus,

      modify,
      drop,
    }),
    [
      data,
      items,
      isLoading,
      modal,
      vote,
      react,
      setModal,
      poll,
      setPollStatus,
      modify,
      drop,
    ],
  );

  return <NewsContext.Provider value={value}>{children}</NewsContext.Provider>;
};

export const useNews = () => useContext<NewsContextType>(NewsContext);
