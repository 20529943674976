import { NavLink } from 'react-router-dom';
import { DateTime } from 'luxon';

export const Terms = () => {
  return (
    <div className={'flex flex-row gap-20 items-center'}>
      <div
        className={
          'flex flex-row gap-2 text-[#99A2AD] dark:text-[#656565] whitespace-nowrap lg:block hidden'
        }
      >
        <a className={'text-[#63A1E4]'} href={'http://nke.team'}>
          Разработано в Центре разработки цифровых сервисов
        </a>
        <span> © 2020-{DateTime.now().toFormat('yyyy')}</span>
      </div>
      <div className={'flex flex-row gap-2 whitespace-nowrap text-[#63A1E4]'}>
        <NavLink to={'/policy'}>Политика</NavLink>
        <NavLink to={'mailto:support@nke.team'}>Поддержка</NavLink>
      </div>
    </div>
  );
};
