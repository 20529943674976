import { api } from '../common/app/networking/axios';
import { RoleEntity } from '../role/role.entity';

export const findAllUserRoles = (userId: number) =>
  api.get<RoleEntity[]>(`/users/${userId}/roles`);

export const addUserRole = (userId: number, roleId: number) =>
  api.post(`/users/${userId}/roles/${roleId}`);

export const removeUserRole = (userId: number, roleId: number) =>
  api.delete(`/users/${userId}/roles/${roleId}`);
