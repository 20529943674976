import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { BuildingEntity } from '../entity/building.entity';
import { SpinningLoader } from '../../common/component/loading/spinning-loader.component';
import { ClassroomEntity } from '../entity/classroom.entity';
import { UserEntity } from '../../user/user.entity';
import { Profile } from '../../common/component/profile/profile.component';
import { useNotifier } from '../../common/app/notification/notification-context';
import { findOne } from '../../common/entity/entity.service';
import { EntityModule } from '../../common/entity';

export type ClassroomParams = {
  buildingId: string;
};

export const ClassroomModule = () => {
  const { notify } = useNotifier();
  const { buildingId } = useParams<ClassroomParams>();

  const [building, setBuilding] = useState<BuildingEntity | undefined>(
    undefined,
  );

  useEffect(() => {
    findOne<BuildingEntity>('buildings', Number(buildingId))
      .then(response => setBuilding(response.data))
      .catch(() => notify('error', 'Ошибка загрузки данных'));
  }, [buildingId]);

  if (!building) {
    return <SpinningLoader center />;
  }

  return (
    <EntityModule<ClassroomEntity>
      title={`${building.title} - Аудитории`}
      route={`/buildings/${buildingId}/classrooms`}
      columns={[
        {
          key: 'title',
          title: 'Название',
          input: {
            type: 'text',
          },
          render: item => item.title,
        },
        {
          key: 'bossId',
          title: 'Заведующий аудиторией',
          short: 'Заведующий',
          input: {
            type: 'service',
            route: '/users/',
            options: {
              required: 'Данное поле обязательно',
            },
            titleFor: item => (item as UserEntity).pretty,
            viewFor: item => <Profile user={item as UserEntity} size={6} />,
          },
          render: item => <Profile user={item.boss} size={10} />,
        },
        {
          key: 'floor',
          title: 'Этаж',
          input: {
            type: 'text',
          },
          render: item => item.floor,
        },
        {
          key: 'inside',
          title: 'Внутри учреждения',
          input: {
            type: 'toggle',
            title: 'Внутри учреждения',
          },
          render: item => item.inside,
        },
        {
          key: 'description',
          title: 'Описание',
          input: {
            type: 'textarea',
            options: {
              required: 'Данное поле обязательно',
            },
          },
        },
      ]}
    />
  );
};
