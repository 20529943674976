import logo from '../../../../assets/logo.svg';

import { LoginCode } from './login-code.component';
import { LoginEmail } from './login-email.component';
import { Card } from '../../../../block/card.component';
import { useAuth } from '../../../../app/auth/auth.context';

export type FormValues = {
  mail: string;
};

export const LoginForm = () => {
  const { auth } = useAuth();

  return (
    <div className={'w-80'}>
      <Card className={'p-4'}>
        <div className={'flex flex-col gap-4 items-center'}>
          <div className={'flex flex-col gap-6 items-center'}>
            <img src={logo} className={'h-20 w-20'} />
            <h1 className={'font-medium text-xl'}>Вход в платформу</h1>
          </div>

          {auth.mail === undefined ? <LoginEmail /> : <LoginCode />}
        </div>
      </Card>
    </div>
  );
};
