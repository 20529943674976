import {
  PlanItemEntity,
  PlanItemHourEntity,
} from '../../../specialty/components/plan/table/plan-item.entity';
import { SpecialityGroupEntity } from '../../../specialty/entity/group.entity';
import { SpecialtyEntity } from '../../../specialty/entity/specialty.entity';
import { SubjectEntity } from '../../../subject/subject.entity';
import { UserEntity } from '../../../user/user.entity';
import { JournalPlanStatus } from './journal-plan-status.entity';

export type JournalCalendarPlanItemEntity = {
  id: number;
  year: number;

  specialty: SpecialtyEntity;

  status: JournalPlanStatusEntity;
  teacher: UserEntity;

  subject: SubjectEntity & {
    academic: PlanItemEntity & {
      hours: PlanItemHourEntity[];
    };
  };
  group: SpecialityGroupEntity;

  categories: JournalCalendarPlanCategoryEntity[];

  updatedAt: number;
  createdAt: number;
};

export type JournalPlanStatusEntity = {
  id: number;
  reviewerId: number;
  status: JournalPlanStatus;
  note: string;
  createdAt: number;
};

export type JournalCalendarPlanCategoryEntity = {
  id: number;
  title: string;
  items: JournalCalendarPlanThemeEntity[];
};

export enum JournalPlanItemType {
  LESSON = 'LESSON',
  LECTURE = 'LECTURE',
  PRACTICAL_WORK = 'PRACTICAL_WORK',
  LAB_WORK = 'LAB_WORK',
  CONTROL_WORK = 'CONTROL_WORK',
  SELF_WORK = 'SELF_WORK',
  CONSULTATION = 'CONSULTATION',
  PRACTICAL = 'PRACTICAL',
  EXCURSION = 'EXCURSION',
  COURSE_DESIGN = 'COURSE_DESIGN',
  COURSE_WORK = 'COURSE_WORK',
  CONFERENCE = 'CONFERENCE',
  SEMINAR = 'SEMINAR',
  DISPUTE = 'DISPUTE',
  BUSINESS_GAME = 'BUSINESS_GAME',
  SOLVING_PRODUCTION_SITUATIONS = 'SOLVING_PRODUCTION_SITUATIONS',
}

export type JournalCalendarPlanThemeEntity = {
  id: number;
  title: string;
  hours: number;
  month: number;
  type: JournalPlanItemType;
  visual: string;
  homework: string;
  note: string;
};
