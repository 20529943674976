import { UserEntity } from '../../../../../user/user.entity';

export type StatementBodyStudentsItemProps = {
  index: number;
  user: UserEntity;
};

export const StatementBodyStudentsItem = ({
  index,
  user,
}: StatementBodyStudentsItemProps) => {
  return (
    <div
      className={
        'flex flex-row w-96 h-10 border-t border-r border-[#F0F2F5] dark:border-[#333333]'
      }
    >
      <div
        className={
          'flex aspect-square h-10 w-10 text-center justify-center items-center border-r border-[#F0F2F5] dark:border-[#333333]'
        }
      >
        {index + 1}
      </div>
      <div className={'flex flex-row p-2 whitespace-nowrap w-full'}>
        {user.full}
      </div>
    </div>
  );
};
