import { useProfile } from '../../profile.context';
import { Card } from '../../../common/block/card.component';
import { SpecialityGroupEntity } from '../../../specialty/entity/group.entity';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../common/component/button/button.component';
import { useAuth } from '../../../common/app/auth/auth.context';
import { PermissionType } from '../../../common/app/auth/auth-role-permission.type';

export const AvailabilitiesSubject = () => {
  const navigate = useNavigate();
  const { result } = useAuth();
  const { availabilities } = useProfile();

  if (availabilities.subjects.length === 0) {
    return <div />;
  }

  const mapped = availabilities.subjects
    .filter(item => item.isTeacher)
    .reduce((previous: Record<number, SpecialityGroupEntity[]>, current) => {
      (previous[current.subject.id] = previous[current.subject.id] || []).push(
        current.group,
      );

      return previous;
    }, {});

  return (
    <div className={'flex flex-col gap-4'}>
      <div className={'flex flex-row items-end justify-end'}>
        <span className={'text-right text-lg font-semibold'}>Нагрузка</span>
      </div>

      {Object.keys(mapped).map(key => {
        const id = Number(key);
        const value = mapped[id];
        const subject = availabilities.subjects.find(
          item => item.subject.id === id,
        )?.subject;

        return (
          <div key={id} className={'flex flex-col gap-2'}>
            <Card className={'flex flex-col gap-4 p-4'}>
              <span className={'text-lg font-semibold'}>{subject?.short}</span>

              <div className={'flex flex-col gap-2'}>
                {value.map((group, index) => (
                  <div
                    key={group.id}
                    className={'flex flex-row justify-between items-center'}
                  >
                    <span>
                      {index + 1}. {group.title}
                    </span>
                    {result?.permissions?.includes(
                      PermissionType.JOURNAL_MODULE_PREVIEW,
                    ) && (
                      <Button
                        small={true}
                        onClick={() =>
                          navigate(
                            `/journals/items/subject/?groupId=${group.id}&subjectId=${subject?.id}`,
                            {},
                          )
                        }
                      >
                        Журнал
                      </Button>
                    )}
                  </div>
                ))}
              </div>
            </Card>
          </div>
        );
      })}
    </div>
  );
};
