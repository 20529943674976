import { useParams } from 'react-router-dom';
import { SpecialtyGroupProvider } from '../context/group.context';
import { SpecialtyGroupParams } from './plan.index';
import { SpecialtyGroupStatementsProvider } from '../context/group-statements.context';
import { StatementRoot } from '../components/group-statements/statement-root.component';

export type SpecialtyGroupStatementsParams = {
  groupId: string;
};

export const SpecialtyGroupStatementsModule = () => {
  const { specialtyId, groupId } = useParams<
    SpecialtyGroupParams & SpecialtyGroupStatementsParams
  >();

  return (
    <SpecialtyGroupProvider specialtyId={specialtyId}>
      <SpecialtyGroupStatementsProvider groupId={groupId}>
        <StatementRoot />
      </SpecialtyGroupStatementsProvider>
    </SpecialtyGroupProvider>
  );
};
