import { usePlan } from '../../../../../../context/plan.context';
import { PlanItemEntity } from '../../../plan-item.entity';

export type PlanTableContentCategoryHeaderExamProps = {
  plan: PlanItemEntity;
};

export const PlanTableContentCategoryHeaderExam = ({
  plan,
}: PlanTableContentCategoryHeaderExamProps) => {
  const { base, specialty, semesters, hoursCountBySemester } = usePlan();

  const years = base === 'FULL' ? specialty.fullYears : specialty.notFullYears;

  return (
    <div className={'flex flex-row w-2/4'}>
      {Array.from(Array(years).keys()).map(year => {
        const filtered = semesters.filter(item => item.year === year);

        return (
          <div key={year} className={'flex w-full'}>
            <div className={`grow flex flex-row w-1/${years}`}>
              {filtered.map((semester, index) => (
                <div
                  key={semester.countThrough}
                  className={`flex flex-row ${
                    filtered.length === 1 ? 'w-full' : `w-1/${filtered.length}`
                  }`}
                >
                  <div
                    className={
                      'flex flex-row text-center items-center justify-center p-2 border-r-2 border-[#F0F2F5] dark:border-[#333333] w-1/2'
                    }
                  >
                    {hoursCountBySemester(plan.id, semester.id)}
                  </div>
                  <div
                    className={`flex flex-row text-center items-center justify-center w-1/2 ${
                      year + 1 === years && index + 1 === filtered.length
                        ? ''
                        : 'border-r-2 border-[#F0F2F5] dark:border-[#333333] w-1/2'
                    }`}
                  >
                    Итог
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};
