import { Control, Controller } from 'react-hook-form';

import {
  EntityAttachmentColumnType,
  EntityColumnType,
} from '../../../entity.type';
import { ErrorCard } from '../../../../block/error-card.component';
import { FileUploader } from '../../../../component/file/file-uploader';

export type EntityAttachmentFieldComponent<ItemType> = {
  control: Control;
  column: EntityColumnType<ItemType>;
  input: EntityAttachmentColumnType;
  error?: string;
};

export const EntityAttachmentField = <ItemType,>({
  control,
  column,
  input,
  error,
}: EntityAttachmentFieldComponent<ItemType>) => {
  return (
    <Controller
      name={column.key}
      control={control}
      render={field => {
        return (
          <div className={'flex flex-col gap-2'}>
            <FileUploader
              maxFiles={1}
              maxSize={1000}
              formats={input.formats}
              collection={input.collection}
              onChange={value =>
                field.field.onChange(input.count === 1 ? value[0] : value)
              }
              isSystem={input.isSystem}
            />
            {error && <ErrorCard error={error} />}
          </div>
        );
      }}
    />
  );
};
