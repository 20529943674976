import { useParams } from 'react-router-dom';
import { GroupTable } from '../components/group/group-table.component';
import { SpecialtyGroupProvider } from '../context/group.context';

export type SpecialtyGroupParams = {
  specialtyId: string;
};

export const SpecialtyGroupModule = () => {
  const { specialtyId } = useParams<SpecialtyGroupParams>();

  return (
    <SpecialtyGroupProvider specialtyId={specialtyId}>
      <GroupTable />
    </SpecialtyGroupProvider>
  );
};
