import { Card } from '../../../common/block/card.component';
import { useBuildingTime } from '../../context/time.context';
import { typeTitle } from '../../type/time.type';
import { useMemo } from 'react';
import { Button } from '../../../common/component/button/button.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Input } from '../../../common/component/input/input.component';
import { DateTime } from 'luxon';

export const TimeSchedule = () => {
  const { type, lessons, add, remove, edit } = useBuildingTime();

  const title = useMemo(() => typeTitle[type], [type]);

  return (
    <Card className={'p-4 flex flex-col gap-4 justify-between items-center'}>
      <div className={'flex flex-row justify-between w-full items-center'}>
        <div className={'text-lg font-bold'}>{title}</div>
        <div className={'flex flex-row gap-2 items-center'}>
          <Button square={true} small={true} color={'blue'} onClick={add}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
          <Button square={true} small={true} color={'red'} onClick={remove}>
            <FontAwesomeIcon icon={faMinus} />
          </Button>
          <div>Количество занятий: {lessons.length}</div>
        </div>
      </div>
      {lessons.length > 0 && (
        <div className={'flex flex-col gap-2 w-full'}>
          {lessons.map((item, index) => (
            <div
              key={index}
              className={'flex flex-row gap-2 w-full items-center'}
            >
              <span className={'w-4'}>{index + 1}.</span>
              <div className={'flex flex-row w-full gap-2'}>
                <div className={'w-1/2'}>
                  <Input
                    placeholder={'Время в формате ЧЧ:ММ'}
                    defaultValue={DateTime.now()
                      .startOf('day')
                      .plus({ minute: item.start })
                      .toFormat('HH:mm')}
                    onChange={event => {
                      const date = DateTime.fromFormat(
                        event.target.value,
                        'hh:mm',
                      );

                      if (date.isValid) {
                        edit(
                          index,
                          'start',
                          Math.abs(
                            DateTime.now().startOf('day').diff(date, 'minutes')
                              .minutes,
                          ),
                        );
                      }
                    }}
                  />
                </div>
                <div className={'w-1/2'}>
                  <Input
                    placeholder={'Время в формате ЧЧ:ММ'}
                    defaultValue={DateTime.now()
                      .startOf('day')
                      .plus({ minute: item.end })
                      .toFormat('HH:mm')}
                    onChange={event => {
                      const date = DateTime.fromFormat(
                        event.target.value,
                        'hh:mm',
                      );

                      if (date.isValid) {
                        edit(
                          index,
                          'end',
                          Math.abs(
                            DateTime.now().startOf('day').diff(date, 'minutes')
                              .minutes,
                          ),
                        );
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </Card>
  );
};
